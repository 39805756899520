import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERS = 'DIVERS'
}

export namespace GenderEnumValues {
  export function getLabelKeys(): EnumLabelKeysModel<GenderEnum>[] {
    return [
      { key: GenderEnum.MALE, label: 'shared.maleGender' },
      { key: GenderEnum.FEMALE, label: 'shared.femaleGender' },
      { key: GenderEnum.DIVERS, label: 'shared.undefinedGender' }
    ];
  }

  export function getEnumByKey(key: GenderEnum): EnumLabelKeysModel<GenderEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }

  export function getEnumByString(value: string): GenderEnum {
    if (value.toLowerCase().trim() === 'male') {
      return GenderEnum.MALE;
    } else if (value.toLowerCase().trim() === 'female') {
      return GenderEnum.FEMALE;
    }

    return GenderEnum.DIVERS;
  }
}
