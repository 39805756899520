import { Component, inject, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { CallidusCurrencyPipe } from '@shared/pipes/currency/callidus-currency.pipe';
import { unsubscribeMixin } from '@shared/mixin/unsubscribe.mixin';
import { take, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { AnswerOption, QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppLanguage } from '@shared/models/app-language.model';
import { LanguagePickerComponent } from '@shared/components/language-picker/language-picker.component';
import { BrokernetLanguageTypeEnum, BrokernetLanguageTypeValues } from '@brokernet/brokernet-ui-lib';
import { UrlParamProviderService } from '@shared/services/url-param-provider.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslocoPipe,
    FormsModule,
    QuestionBoxComponent,
    DropdownModule,
    OverlayPanelModule,
    LanguagePickerComponent,
    RouterLink
  ],
  providers: [
    CurrencyPipe,
    CallidusCurrencyPipe
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends unsubscribeMixin() implements OnInit {

  private primengConfig = inject(PrimeNGConfig);
  private translocoService = inject(TranslocoService);
  private router = inject(Router);
  private urlParamProviderService = inject(UrlParamProviderService);

  insuranceTypeAnswerOptions: AnswerOption<ContractSelectOptionsEnum>[] = [
    { title: 'bvs.kasko', id: ContractSelectOptionsEnum.BVS_HAFTPFLICHT_UND_KASKO, value: ContractSelectOptionsEnum.BVS_HAFTPFLICHT_UND_KASKO },
    { title: 'bvs.haftpflicht', id: ContractSelectOptionsEnum.BVS_HAFTPFLICHT, value: ContractSelectOptionsEnum.BVS_HAFTPFLICHT },
    { title: 'contract.skipper', id: ContractSelectOptionsEnum.SKIPPER, value: ContractSelectOptionsEnum.SKIPPER },
    { title: 'contract.bvs.charterkautionsversicherung', id: ContractSelectOptionsEnum.CHARTER_KAUTION, value: ContractSelectOptionsEnum.CHARTER_KAUTION }
  ];

  languageOptions: AppLanguage[] = BrokernetLanguageTypeValues
    .getAll()
    .map(lang => {
      return {
        code: lang.key,
        name: lang.label,
        flagIcon: this.getFlagForLangCode(lang.key)
      };
    });
  selectedContractOption: ContractSelectOptionsEnum | null = null;

  selectedLanguage: AppLanguage = this.languageOptions.find(l => l.code === this.translocoService.getActiveLang())!;

  ngOnInit(): void {
    this.urlParamProviderService.storeParamsFromUrl();

    if (!localStorage.getItem('app-language')) {
      localStorage.setItem('app-language', BrokernetLanguageTypeEnum.DE);
    }

    this.router
      .events
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/') {
            this.selectedContractOption = null;
          } else {
            this.selectedContractOption = this.getContractSelectOptionByRoute((event as NavigationEnd).url);
          }
        }

      });

    this.translocoService.load(BrokernetLanguageTypeEnum.DE)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.primengConfig.setTranslation(this.translocoService.getTranslation('primeng')));
  }

  setSelectedLanguage(language: AppLanguage): void {
    this.initLanguageSettings(language.code);
    this.selectedLanguage = language;
    localStorage.setItem('app-language', language.code);
  }

  routeToContractPage(contractSelectOption: ContractSelectOptionsEnum): void {
    this.selectedContractOption = contractSelectOption;

    let route = '';

    switch (contractSelectOption) {
      case ContractSelectOptionsEnum.BVS_HAFTPFLICHT:
        route = '/bvs/haftpflicht';
        break;

      case ContractSelectOptionsEnum.BVS_HAFTPFLICHT_UND_KASKO:
        route = '/bvs/kasko';
        break;

      case ContractSelectOptionsEnum.CHARTER_KAUTION:
        route = '/bvs/charter';
        break;

      case ContractSelectOptionsEnum.SKIPPER:
        route = '/skipper';
        break;
    }

    this.router.navigate([route], { queryParams: this.urlParamProviderService.getQueryData() });
  }

  private initLanguageSettings(lang: BrokernetLanguageTypeEnum): void {
    this.translocoService
      .load(lang.toLowerCase())
      .pipe(take(1))
      .subscribe(() => this.primengConfig.setTranslation(this.translocoService.getTranslation('primeng')));

    this.translocoService.setActiveLang(lang);
  }

  private getFlagForLangCode(langCode: BrokernetLanguageTypeEnum): string {
    if (langCode === BrokernetLanguageTypeEnum.DE) {
      return 'austria';
    }

    return 'united-kingdom';
  }

  private getContractSelectOptionByRoute(route: string): ContractSelectOptionsEnum | null {
    // ignore parameters like customerId
    const cleanRoute = route.split('?')[0];

    switch (cleanRoute) {
      case '/bvs/haftpflicht':
        return ContractSelectOptionsEnum.BVS_HAFTPFLICHT;
      case '/bvs/kasko':
        return ContractSelectOptionsEnum.BVS_HAFTPFLICHT_UND_KASKO;
      case '/bvs/charter':
        return ContractSelectOptionsEnum.CHARTER_KAUTION;
      case '/skipper':
        return ContractSelectOptionsEnum.SKIPPER;
      default:
        return null;
    }
  }

}

enum ContractSelectOptionsEnum {
  BVS_HAFTPFLICHT_UND_KASKO = 'BVS_HAFTPFLICHT_UND_KASKO',
  BVS_HAFTPFLICHT = 'BVS_HAFTPFLICHT',
  SKIPPER = 'SKIPPER',
  CHARTER_KAUTION = 'CHARTER_KAUTION'
}

