import { Injectable } from '@angular/core';
import { GenderEnum } from '@brokernet/brokernet-ui-lib';
import { GenderEnumValues } from '@shared/models/gender.enum';

@Injectable({ providedIn: 'root' })
export class UrlParamProviderService {
  private queryData: QueryDataModel = {};

  /**
   * extracts all the given params from url (firstname, lastname etc.) and stores it into a service
   */
  storeParamsFromUrl(): void {
    const url = new URL(window.location.href);

    if (url.searchParams.has('advertiserId')) {
      this.queryData.goAdvertisementNumber = url.searchParams.get('advertiserId')!;
    }

    if (url.searchParams.has('customerId')) {
      this.queryData.customerId = url.searchParams.get('customerId')!;
    }

    if (url.searchParams.has('firstname')) {
      this.queryData.firstname = url.searchParams.get('firstname')!;
    }

    if (url.searchParams.has('lastname')) {
      this.queryData.lastname = url.searchParams.get('lastname')!;
    }

    if (url.searchParams.has('email')) {
      this.queryData.email = url.searchParams.get('email')!;
    }

    if (url.searchParams.has('phone')) {
      this.queryData.phone = url.searchParams.get('phone')!;
    }

    if (url.searchParams.has('token')) {
      this.queryData.token = url.searchParams.get('token')!;
    }

    if (url.searchParams.has('birthdate')) {
      this.queryData.birthDate = url.searchParams.get('birthdate')!;
    }

    if (url.searchParams.has('gender')) {
      this.queryData.gender = GenderEnumValues.getEnumByString(url.searchParams.get('gender')!);
    }
  }

  getQueryData(): QueryDataModel | null {
    return this.queryData;
  }
}

export interface QueryDataModel {
  customerId?: string;
  token?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  gender?: GenderEnum;
  goAdvertisementNumber?: string;
  birthDate?: string;
  phone?: string;
}
