import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubmitHaftpflichtCommandModel, SubmitHaftpflichtKaskoCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { environment } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class BvsApiService {

  http = inject(HttpClient);

  get endpoint(): string {
    return environment.basePath + '/api/bvs/public';
  }

  submit(command: SubmitHaftpflichtCommandModel | SubmitHaftpflichtKaskoCommandModel): Observable<Object> {
    return this.http.post<Object>(`${this.endpoint}/onboarding`, command);
  }
}
