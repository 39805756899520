import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { of, throwError } from 'rxjs';
import { retryWhen, delay, mergeMap } from 'rxjs/operators';
const isNotNullOrUndefined = input => input !== null && input !== undefined;
const isNotNullOrUndefinedOrEmpty = input => {
  if (input instanceof Array) {
    return input.length !== 0;
  }
  return isNotNullOrUndefined(input);
};
const isNotNullOrUndefinedOrZero = input => {
  if (input === 0) {
    return false;
  }
  return isNotNullOrUndefined(input);
};
const isNullOrUndefinedOrEmpty = input => {
  return !isNotNullOrUndefined(input);
};

/**
 * checks if a string is null, undefined or empty ('')
 */
function isStringEmptyOrNull(value) {
  if (value === '') {
    return true;
  }
  return !isNotNullOrUndefined(value);
}
/**
 * capitalizes the first letter of a string (foo -> Foo)
 */
function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
class IbanFormatPipe {
  transform(value) {
    if (isStringEmptyOrNull(value)) {
      return null;
    }
    return value.replace(' ', '').replace(/(.{4})/g, '$1' + ' ').trim();
  }
  static ɵfac = function IbanFormatPipe_Factory(t) {
    return new (t || IbanFormatPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "ibanFormat",
    type: IbanFormatPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IbanFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'ibanFormat'
    }]
  }], null, null);
})();
class IbanFormatPipeModule {
  static ɵfac = function IbanFormatPipeModule_Factory(t) {
    return new (t || IbanFormatPipeModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: IbanFormatPipeModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IbanFormatPipeModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [IbanFormatPipe],
      exports: [IbanFormatPipe]
    }]
  }], null, null);
})();
function setValidatorsOnAllControlsInForm(form, validators, emitEvent = true) {
  for (const controlsKey in form.controls) {
    form.get(controlsKey).setValidators(validators);
    form.get(controlsKey).updateValueAndValidity({
      emitEvent
    });
  }
}
/**
 * checks if passed formGroup is invalid
 * ignoreFields is used to skip checking validity on provided nested form groups
 */
function isFormGroupInvalidExcept(formGroup, ignoreFields) {
  return !!Object.keys(formGroup.value).find(key => {
    if (!ignoreFields?.includes(key)) {
      if (!formGroup.controls[key].valid) return true;
    }
    return false;
  });
}
class BicValidator {
  /**
   * BIC is the business identifier code (ISO 9362). This BIC check is not a guarantee for authenticity.
   *
   * BIC pattern: BBBBCCLLbbb (8 or 11 characters long; bbb is optional)
   *
   * BIC definition in detail:
   * - First 4 characters - bank code (only letters)
   * - Next 2 characters - ISO 3166-1 alpha-2 country code (only letters)
   * - Next 2 characters - location code (letters and digits)
   *   a. shall not start with '0' or '1'
   *   b. second character must be a letter ('O' is not allowed) or one of the following digits ('0' for test (therefore not allowed), '1' for passive participant and '2' for active participant)
   * - Last 3 characters - branch code, optional (shall not start with 'X' except in case of 'XXX' for primary office) (letters and digits)
   */
  static bicValidator() {
    return control => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(value.toUpperCase()) ? null : {
        'invalidBic': {
          value: value
        }
      };
    };
  }
}
class DateValidator {
  static FORMAT_DATE = 'YYYY-MM-DD';
  /**
   * @param date the minimum date to check on
   * @param respectTimestamp handles if the given dates should be checked with the current timestamp or with 00:00:00
   */
  static minDate(date, respectTimestamp = false) {
    return control => {
      if (control.value == null) {
        return null;
      }
      const controlDate = new Date(control.value);
      const validationDate = new Date(date);
      if (!respectTimestamp) {
        controlDate.setUTCHours(0, 0, 0, 0);
        validationDate.setUTCHours(0, 0, 0, 0);
      }
      return controlDate >= validationDate ? null : {
        'dateMinimum': {
          'dateMinimum': validationDate.toISOString(),
          'actual': controlDate.toISOString()
        }
      };
    };
  }
  /**
   * @param date the maximum date to check on
   * @param respectTimestamp handles if the given dates should be checked with the current timestamp or with 00:00:00
   */
  static maxDate(date, respectTimestamp = false) {
    return control => {
      if (control.value == null) {
        return null;
      }
      const controlDate = new Date(control.value);
      const validationDate = new Date(date);
      if (!respectTimestamp) {
        controlDate.setUTCHours(0, 0, 0, 0);
        validationDate.setUTCHours(0, 0, 0, 0);
      }
      return controlDate <= validationDate ? null : {
        'dateMaximum': {
          'dateMaximum': validationDate.toISOString(),
          'actual': controlDate.toISOString()
        }
      };
    };
  }
}
class DayMonthValidator {
  static dayMonthValidator() {
    return control => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])$/.test(value) ? null : {
        'invalidDate': {
          value: value
        }
      };
    };
  }
}
class IbanValidator {
  static CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26
  };
  static ibanValidator() {
    return control => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const iban = String(value).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
      const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
      let digits = null;
      // check syntax and length
      // @ts-ignore
      if (!code || iban.length !== this.CODE_LENGTHS[code[1]]) {
        return {
          invalidIban: true
        };
      }
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => this.uppercase(letter));
      // final check
      return this.mod97(digits) === 1 ? null : {
        'invalidIban': {
          value: value
        }
      };
    };
  }
  static uppercase(substring) {
    return substring.charCodeAt(0) - 55;
  }
  static mod97(value) {
    let checksum = parseInt(value.slice(0, 2));
    let fragment;
    for (let offset = 2; offset < value.length; offset += 7) {
      fragment = String(checksum) + value.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }
}
class MinMaxValidator {
  static minmaxValidator(min, max) {
    return control => {
      if (control.value.length < min || control.value.length > max) return {
        'minmaxError': `Wählen Sie min. ${min} und max. ${max} Einträge aus`
      };
      return null;
    };
  }
}
class MonthYearValidator {
  static monthYearValidator() {
    return control => {
      const value = control.value;
      if (!value) {
        return null;
      }
      return /^(0[1-9]|1[0-2]|[1-9]).(19[7-9][0-9]|20[0-9][0-9]|21[0-9][0-9]|22[0-1][0-9])$/.test(value) ? null : {
        'invalidDate': {
          value: value
        }
      };
    };
  }
}
var RegularCommissionTypeEnum;
(function (RegularCommissionTypeEnum) {
  /**
   * kein Honorar, nur Provision
   */
  RegularCommissionTypeEnum["KEIN_HONORAR"] = "KEIN_HONORAR";
  /**
   * Fixes Jahreshonorar in EUR, keine Provision
   */
  RegularCommissionTypeEnum["FIXES_JAHRESHONORAR_IN_EUR"] = "FIXES_JAHRESHONORAR_IN_EUR";
  /**
   * Fixes Jahreshonorar in %, keine Provision
   */
  RegularCommissionTypeEnum["FIXES_JAHRESHONORAR_IN_PROZENT"] = "FIXES_JAHRESHONORAR_IN_PROZENT";
  /**
   * kein Honorar, keine Provision
   */
  RegularCommissionTypeEnum["UNENTGELTLICH"] = "UNENTGELTLICH";
})(RegularCommissionTypeEnum || (RegularCommissionTypeEnum = {}));
var SpecialCommissionTypeEnum;
(function (SpecialCommissionTypeEnum) {
  SpecialCommissionTypeEnum["UNENTGELTLICH"] = "UNENTGELTLICH";
  SpecialCommissionTypeEnum["IN_EURO"] = "IN_EURO";
  SpecialCommissionTypeEnum["IN_PROZENT"] = "IN_PROZENT";
})(SpecialCommissionTypeEnum || (SpecialCommissionTypeEnum = {}));
var CompanyTypeEnum;
(function (CompanyTypeEnum) {
  /**
   * Hotel
   */
  CompanyTypeEnum["HOTEL"] = "HOTEL";
  /**
   * Bau- / Baunebengewerbe
   */
  CompanyTypeEnum["CONSTRUCTION"] = "CONSTRUCTION";
  /**
   * Handel
   */
  CompanyTypeEnum["COMMERCE"] = "COMMERCE";
  /**
   * Dienstleistung
   */
  CompanyTypeEnum["SERVICE"] = "SERVICE";
  /**
   * Produzent
   */
  CompanyTypeEnum["MANUFACTURER"] = "MANUFACTURER";
  /**
   * Freier Beruf
   */
  CompanyTypeEnum["FREELANCE"] = "FREELANCE";
  CompanyTypeEnum["IT"] = "IT";
  /**
   * Werbung
   */
  CompanyTypeEnum["ADVERTISEMENT"] = "ADVERTISEMENT";
  CompanyTypeEnum["CONSULTING"] = "CONSULTING";
})(CompanyTypeEnum || (CompanyTypeEnum = {}));
var CompanyTypeEnumValues;
(function (CompanyTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: CompanyTypeEnum.HOTEL,
      label: 'customer.hotel'
    }, {
      key: CompanyTypeEnum.CONSTRUCTION,
      label: 'customer.construction'
    }, {
      key: CompanyTypeEnum.COMMERCE,
      label: 'customer.commerce'
    }, {
      key: CompanyTypeEnum.SERVICE,
      label: 'customer.service'
    }, {
      key: CompanyTypeEnum.MANUFACTURER,
      label: 'customer.manufacturer'
    }, {
      key: CompanyTypeEnum.FREELANCE,
      label: 'customer.freelance'
    }, {
      key: CompanyTypeEnum.IT,
      label: 'customer.itCompany'
    }, {
      key: CompanyTypeEnum.ADVERTISEMENT,
      label: 'customer.advertisement'
    }, {
      key: CompanyTypeEnum.CONSULTING,
      label: 'customer.consulting'
    }];
  }
  CompanyTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  CompanyTypeEnumValues.getEnumByKey = getEnumByKey;
})(CompanyTypeEnumValues || (CompanyTypeEnumValues = {}));
var CustomerTypeEnum;
(function (CustomerTypeEnum) {
  CustomerTypeEnum["PRIVATE_CUSTOMER"] = "PRIVATE_CUSTOMER";
  CustomerTypeEnum["COMPANY_CUSTOMER"] = "COMPANY_CUSTOMER";
})(CustomerTypeEnum || (CustomerTypeEnum = {}));
var CustomerTypeEnumValues;
(function (CustomerTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: CustomerTypeEnum.COMPANY_CUSTOMER,
      label: 'customer.companyCustomer'
    }, {
      key: CustomerTypeEnum.PRIVATE_CUSTOMER,
      label: 'customer.privateCustomer'
    }];
  }
  CustomerTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  CustomerTypeEnumValues.getEnumByKey = getEnumByKey;
})(CustomerTypeEnumValues || (CustomerTypeEnumValues = {}));
var CustomerPermissionEnum;
(function (CustomerPermissionEnum) {
  /**
   * Customer can access Stella-APP.
   */
  CustomerPermissionEnum["CUSTOMER_STELLA_ACCESS"] = "CUSTOMER_STELLA_ACCESS";
  /**
   * Customer can send/read chat messages to/from Broker. (Stella-APP)
   */
  CustomerPermissionEnum["CUSTOMER_STELLA_CHAT"] = "CUSTOMER_STELLA_CHAT";
  /**
   * Customer can report claims (Schäden). (Stella-APP)
   */
  CustomerPermissionEnum["CUSTOMER_STELLA_CLAIMS"] = "CUSTOMER_STELLA_CLAIMS";
})(CustomerPermissionEnum || (CustomerPermissionEnum = {}));
var GenderEnum;
(function (GenderEnum) {
  GenderEnum["MALE"] = "MALE";
  GenderEnum["FEMALE"] = "FEMALE";
  GenderEnum["DIVERS"] = "DIVERS";
})(GenderEnum || (GenderEnum = {}));
var GenderEnumValues;
(function (GenderEnumValues) {
  function getLabelKeys() {
    return [{
      key: GenderEnum.MALE,
      label: 'shared.male'
    }, {
      key: GenderEnum.FEMALE,
      label: 'shared.female'
    }, {
      key: GenderEnum.DIVERS,
      label: 'shared.undefined'
    }];
  }
  GenderEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  GenderEnumValues.getEnumByKey = getEnumByKey;
})(GenderEnumValues || (GenderEnumValues = {}));
var LastUpdateSourceEnum;
(function (LastUpdateSourceEnum) {
  LastUpdateSourceEnum["ABFRAGE_JAHRESZAHLEN"] = "ABFRAGE_JAHRESZAHLEN";
  LastUpdateSourceEnum["MANUELL_DURCH_BROKER"] = "MANUELL_DURCH_BROKER";
  LastUpdateSourceEnum["FIRMENABFRAGE"] = "FIRMENABFRAGE";
})(LastUpdateSourceEnum || (LastUpdateSourceEnum = {}));

/**
 * Rechtsform des Unternehmens
 */
var LegalFormEnum;
(function (LegalFormEnum) {
  /**
   * Einzelunternehmen
   */
  LegalFormEnum["SINGLE_COMPANY"] = "SINGLE_COMPANY";
  /**
   * GmbH
   */
  LegalFormEnum["LTD"] = "LTD";
  /**
   * GmbH. und Co. KG
   */
  LegalFormEnum["LTD_EXTRA"] = "LTD_EXTRA";
  /**
   * Aktiengesellschaft
   */
  LegalFormEnum["STOCK_COMPANY"] = "STOCK_COMPANY";
  /**
   * Offene Gesellschaft
   */
  LegalFormEnum["OPEN_SOCIETY"] = "OPEN_SOCIETY";
  /**
   * Kommanditgesellschaft
   */
  LegalFormEnum["LTD_PARTNERSHIP"] = "LTD_PARTNERSHIP";
})(LegalFormEnum || (LegalFormEnum = {}));
var LegalFormEnumValues;
(function (LegalFormEnumValues) {
  function getLabelKeys() {
    return [{
      key: LegalFormEnum.SINGLE_COMPANY,
      label: 'customer.singleCompany'
    }, {
      key: LegalFormEnum.LTD,
      label: 'customer.ltd'
    }, {
      key: LegalFormEnum.LTD_EXTRA,
      label: 'customer.ltdcokg'
    }, {
      key: LegalFormEnum.STOCK_COMPANY,
      label: 'customer.agency'
    }, {
      key: LegalFormEnum.OPEN_SOCIETY,
      label: 'customer.og'
    }, {
      key: LegalFormEnum.LTD_PARTNERSHIP,
      label: 'customer.kg'
    }];
  }
  LegalFormEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  LegalFormEnumValues.getEnumByKey = getEnumByKey;
})(LegalFormEnumValues || (LegalFormEnumValues = {}));
var DeckungRegionEnum;
(function (DeckungRegionEnum) {
  DeckungRegionEnum["NEIN"] = "NEIN";
  DeckungRegionEnum["EUROPA_GEOGRAFISCH"] = "EUROPA_GEOGRAFISCH";
  DeckungRegionEnum["WELT_EXKLUSIVE_USA_KANADA_AUSTRALIEN"] = "WELT_EXKLUSIVE_USA_KANADA_AUSTRALIEN";
  DeckungRegionEnum["WELT"] = "WELT";
})(DeckungRegionEnum || (DeckungRegionEnum = {}));
var DeckungRegionEnumValues;
(function (DeckungRegionEnumValues) {
  function getLabelKeys() {
    return [{
      key: DeckungRegionEnum.NEIN,
      label: 'shared.no'
    }, {
      key: DeckungRegionEnum.EUROPA_GEOGRAFISCH,
      label: 'riskList.europeGeographic'
    }, {
      key: DeckungRegionEnum.WELT_EXKLUSIVE_USA_KANADA_AUSTRALIEN,
      label: 'riskList.worldExcUsa'
    }, {
      key: DeckungRegionEnum.WELT,
      label: 'customer.world'
    }];
  }
  DeckungRegionEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  DeckungRegionEnumValues.getEnumByKey = getEnumByKey;
})(DeckungRegionEnumValues || (DeckungRegionEnumValues = {}));
var AuswahlSachverstaendigerEnum;
(function (AuswahlSachverstaendigerEnum) {
  AuswahlSachverstaendigerEnum["VU"] = "VU";
  AuswahlSachverstaendigerEnum["KUNDE"] = "KUNDE";
})(AuswahlSachverstaendigerEnum || (AuswahlSachverstaendigerEnum = {}));
var AuswahlSachverstaendigerEnumValues;
(function (AuswahlSachverstaendigerEnumValues) {
  function getLabelKeys() {
    return [{
      key: AuswahlSachverstaendigerEnum.VU,
      label: 'customer.vu'
    }, {
      key: AuswahlSachverstaendigerEnum.KUNDE,
      label: 'customer.customer'
    }];
  }
  AuswahlSachverstaendigerEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  AuswahlSachverstaendigerEnumValues.getEnumByKey = getEnumByKey;
})(AuswahlSachverstaendigerEnumValues || (AuswahlSachverstaendigerEnumValues = {}));
var BrokerDocumentVersionActionEnum;
(function (BrokerDocumentVersionActionEnum) {
  BrokerDocumentVersionActionEnum["UPDATE_MAJOR_VERSION"] = "UPDATE_MAJOR_VERSION";
  BrokerDocumentVersionActionEnum["UPDATE_MINOR_VERSION"] = "UPDATE_MINOR_VERSION";
  BrokerDocumentVersionActionEnum["UP_TO_DATE"] = "UP_TO_DATE";
})(BrokerDocumentVersionActionEnum || (BrokerDocumentVersionActionEnum = {}));
var BuildingTypeEnum;
(function (BuildingTypeEnum) {
  BuildingTypeEnum["NEUBAU"] = "NEUBAU";
  BuildingTypeEnum["UMBAU"] = "UMBAU";
})(BuildingTypeEnum || (BuildingTypeEnum = {}));
var BuildingTypeEnumValues;
(function (BuildingTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: BuildingTypeEnum.NEUBAU,
      label: 'building.newBuilding'
    }, {
      key: BuildingTypeEnum.UMBAU,
      label: 'building.oldBuilding'
    }];
  }
  BuildingTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  BuildingTypeEnumValues.getEnumByKey = getEnumByKey;
})(BuildingTypeEnumValues || (BuildingTypeEnumValues = {}));
var GebaeudeTypeEnumValues;
(function (GebaeudeTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: BuildingTypeEnum.NEUBAU,
      label: 'building.newBuilding'
    }, {
      key: BuildingTypeEnum.UMBAU,
      label: 'building.oldBuilding'
    }];
  }
  GebaeudeTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  GebaeudeTypeEnumValues.getEnumByKey = getEnumByKey;
})(GebaeudeTypeEnumValues || (GebaeudeTypeEnumValues = {}));
var ConfirmDialogResultEnum;
(function (ConfirmDialogResultEnum) {
  ConfirmDialogResultEnum[ConfirmDialogResultEnum["CANCEL"] = 0] = "CANCEL";
  ConfirmDialogResultEnum[ConfirmDialogResultEnum["NO"] = 1] = "NO";
  ConfirmDialogResultEnum[ConfirmDialogResultEnum["YES"] = 2] = "YES";
})(ConfirmDialogResultEnum || (ConfirmDialogResultEnum = {}));
var JahresgespraechContactTypeEnum;
(function (JahresgespraechContactTypeEnum) {
  JahresgespraechContactTypeEnum["CUSTOMER"] = "CUSTOMER";
  JahresgespraechContactTypeEnum["TAX_CONSULTANT"] = "TAX_CONSULTANT";
})(JahresgespraechContactTypeEnum || (JahresgespraechContactTypeEnum = {}));
var InvestitionTypeEnum;
(function (InvestitionTypeEnum) {
  InvestitionTypeEnum["GEBAEUDE"] = "GEBAEUDE";
  InvestitionTypeEnum["WARE"] = "WARE";
  InvestitionTypeEnum["EINRICHTUNG"] = "EINRICHTUNG";
})(InvestitionTypeEnum || (InvestitionTypeEnum = {}));
var JahresgespraechStatusEnum;
(function (JahresgespraechStatusEnum) {
  JahresgespraechStatusEnum["REQUESTED"] = "REQUESTED";
  JahresgespraechStatusEnum["REMINDED"] = "REMINDED";
  JahresgespraechStatusEnum["EXPIRED"] = "EXPIRED";
  JahresgespraechStatusEnum["FINISHED"] = "FINISHED";
  JahresgespraechStatusEnum["READY_FOR_REVIEW"] = "READY_FOR_REVIEW";
})(JahresgespraechStatusEnum || (JahresgespraechStatusEnum = {}));
var InvestitionStatusEnum;
(function (InvestitionStatusEnum) {
  InvestitionStatusEnum["GELOESCHT"] = "GELOESCHT";
  InvestitionStatusEnum["ANGELEGT_VON_JAHRESZAHLEN"] = "ANGELEGT_VON_JAHRESZAHLEN";
  InvestitionStatusEnum["LAUFEND"] = "LAUFEND";
  InvestitionStatusEnum["ABGESCHLOSSEN_VON_JAHRESZAHLEN"] = "ABGESCHLOSSEN_VON_JAHRESZAHLEN";
  InvestitionStatusEnum["ABGESCHLOSSEN"] = "ABGESCHLOSSEN";
})(InvestitionStatusEnum || (InvestitionStatusEnum = {}));
var CountryEnum;
(function (CountryEnum) {
  CountryEnum["AUSTRIA"] = "AUSTRIA";
  CountryEnum["GERMANY"] = "GERMANY";
  CountryEnum["CROATIA"] = "CROATIA";
  CountryEnum["SLOVENIA"] = "SLOVENIA";
})(CountryEnum || (CountryEnum = {}));
var CountryEnumValues;
(function (CountryEnumValues) {
  function getLabelKeys() {
    return [{
      key: CountryEnum.AUSTRIA,
      label: `shared.${CountryEnum.AUSTRIA.toLowerCase()}`
    }, {
      key: CountryEnum.GERMANY,
      label: `shared.${CountryEnum.GERMANY.toLowerCase()}`
    }, {
      key: CountryEnum.CROATIA,
      label: `shared.${CountryEnum.CROATIA.toLowerCase()}`
    }, {
      key: CountryEnum.SLOVENIA,
      label: `shared.${CountryEnum.SLOVENIA.toLowerCase()}`
    }];
  }
  CountryEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  CountryEnumValues.getEnumByKey = getEnumByKey;
})(CountryEnumValues || (CountryEnumValues = {}));
var StateEnum;
(function (StateEnum) {
  StateEnum["NIEDEROESTERREICH"] = "NIEDEROESTERREICH";
  StateEnum["OBEROESTERREICH"] = "OBEROESTERREICH";
  StateEnum["STEIERMARK"] = "STEIERMARK";
  StateEnum["KAERNTEN"] = "KAERNTEN";
  StateEnum["SALZBURG"] = "SALZBURG";
  StateEnum["TIROL"] = "TIROL";
  StateEnum["VORARLBERG"] = "VORARLBERG";
  StateEnum["BURGENLAND"] = "BURGENLAND";
  StateEnum["WIEN"] = "WIEN";
})(StateEnum || (StateEnum = {}));
function createContract() {
  return {
    id: null,
    contractVersion: null,
    expireDate: null,
    customer: null,
    additionalInsurances: [],
    additionalInsuranceComment: null,
    paymentInformation: {},
    insuranceRate: null,
    insuranceRatePaymentPeriod: null,
    paymentDueDate: null,
    contractStatus: null,
    polizzeNumber: null,
    veraProperties: {}
  };
}
var ContractTypeEnum;
(function (ContractTypeEnum) {
  ContractTypeEnum["PRIVATE"] = "PRIVATE";
  ContractTypeEnum["HOTELKONZEPT"] = "HOTELKONZEPT";
  ContractTypeEnum["BAU_HAFTPFLICHT"] = "BAU_HAFTPFLICHT";
  ContractTypeEnum["BAU_HAFTPFLICHT_EXCEDENT"] = "BAU_HAFTPFLICHT_EXCEDENT";
  ContractTypeEnum["GEWERBLICHE_SACHVERSICHERUNG"] = "GEWERBLICHE_SACHVERSICHERUNG";
  ContractTypeEnum["HAFTPFLICHTVERSICHERUNG"] = "HAFTPFLICHTVERSICHERUNG";
  ContractTypeEnum["BVS_HAFTPFLICHT"] = "BVS_HAFTPFLICHT";
  ContractTypeEnum["BVS_HAFTPFLICHT_UND_KASKO"] = "BVS_HAFTPFLICHT_UND_KASKO";
  ContractTypeEnum["SKIPPER_HAFTPFLICHT"] = "SKIPPER_HAFTPFLICHT";
  ContractTypeEnum["SKIPPER_RECHTSSCHUTZ"] = "SKIPPER_RECHTSSCHUTZ";
  ContractTypeEnum["CHARTER_KAUTION"] = "CHARTER_KAUTION";
  ContractTypeEnum["SERVO_ELEKTRONIK"] = "SERVO_ELEKTRONIK";
  ContractTypeEnum["SERVO_GARANTIEVERLAENGERUNG"] = "SERVO_GARANTIEVERLAENGERUNG";
  ContractTypeEnum["SERVO_BIKE_E_BIKE"] = "SERVO_BIKE_E_BIKE";
  ContractTypeEnum["SERVO_KAUFPREIS_KASKO"] = "SERVO_KAUFPREIS_KASKO";
  ContractTypeEnum["VERA"] = "VERA";
})(ContractTypeEnum || (ContractTypeEnum = {}));
var ContractTypeEnumValues;
(function (ContractTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: ContractTypeEnum.HOTELKONZEPT,
      label: 'contract.hotelConcept'
    }, {
      key: ContractTypeEnum.BAU_HAFTPFLICHT_EXCEDENT,
      label: 'contract.bauHaftpflichtExcedent'
    }, {
      key: ContractTypeEnum.BAU_HAFTPFLICHT,
      label: 'contract.bauHaftpflicht'
    }, {
      key: ContractTypeEnum.PRIVATE,
      label: 'contract.private'
    }, {
      key: ContractTypeEnum.GEWERBLICHE_SACHVERSICHERUNG,
      label: 'contract.gewerblicheSva'
    }, {
      key: ContractTypeEnum.HAFTPFLICHTVERSICHERUNG,
      label: 'contract.hva'
    }, {
      key: ContractTypeEnum.BVS_HAFTPFLICHT,
      label: 'contract.bvs.haftpflicht'
    }, {
      key: ContractTypeEnum.BVS_HAFTPFLICHT_UND_KASKO,
      label: 'contract.bvs.haftpflichtUndKasko'
    }, {
      key: ContractTypeEnum.SKIPPER_HAFTPFLICHT,
      label: 'contract.skipper.haftpflicht'
    }, {
      key: ContractTypeEnum.SKIPPER_RECHTSSCHUTZ,
      label: 'contract.skipper.rechtsschutz'
    }, {
      key: ContractTypeEnum.CHARTER_KAUTION,
      label: 'contract.bvs.charterkautionsversicherung'
    }, {
      key: ContractTypeEnum.SERVO_ELEKTRONIK,
      label: 'contract.servo.elektronik'
    }, {
      key: ContractTypeEnum.SERVO_GARANTIEVERLAENGERUNG,
      label: 'contract.servo.garantie'
    }, {
      key: ContractTypeEnum.SERVO_BIKE_E_BIKE,
      label: 'contract.servo.bike'
    }, {
      key: ContractTypeEnum.SERVO_KAUFPREIS_KASKO,
      label: 'contract.servo.kaufpreis'
    }, {
      key: ContractTypeEnum.VERA,
      label: 'contract.vera'
    }];
  }
  ContractTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  ContractTypeEnumValues.getEnumByKey = getEnumByKey;
})(ContractTypeEnumValues || (ContractTypeEnumValues = {}));
var BuildingConstructionTypeEnum;
(function (BuildingConstructionTypeEnum) {
  BuildingConstructionTypeEnum["MASSIV"] = "MASSIV";
  BuildingConstructionTypeEnum["HOLZ"] = "HOLZ";
})(BuildingConstructionTypeEnum || (BuildingConstructionTypeEnum = {}));
var BuildingRoofTypeEnum;
(function (BuildingRoofTypeEnum) {
  BuildingRoofTypeEnum["HART"] = "HART";
  BuildingRoofTypeEnum["WEICH"] = "WEICH";
})(BuildingRoofTypeEnum || (BuildingRoofTypeEnum = {}));
var BauHauptGewerbeSektorEnum;
(function (BauHauptGewerbeSektorEnum) {
  BauHauptGewerbeSektorEnum["BAUMEISTER"] = "BAUMEISTER";
  BauHauptGewerbeSektorEnum["PLANENDER_BAUMEISTER"] = "PLANENDER_BAUMEISTER";
  BauHauptGewerbeSektorEnum["BAUGEWERBE_TREIBENDER"] = "BAUGEWERBE_TREIBENDER";
})(BauHauptGewerbeSektorEnum || (BauHauptGewerbeSektorEnum = {}));
var BauHauptGewerbeSektorEnumValues;
(function (BauHauptGewerbeSektorEnumValues) {
  function getLabelKeys() {
    return [{
      key: BauHauptGewerbeSektorEnum.BAUMEISTER,
      label: 'constructionSectors.mainSector1'
    }, {
      key: BauHauptGewerbeSektorEnum.PLANENDER_BAUMEISTER,
      label: 'constructionSectors.mainSector2'
    }, {
      key: BauHauptGewerbeSektorEnum.BAUGEWERBE_TREIBENDER,
      label: 'constructionSectors.mainSector3'
    }];
  }
  BauHauptGewerbeSektorEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  BauHauptGewerbeSektorEnumValues.getEnumByKey = getEnumByKey;
})(BauHauptGewerbeSektorEnumValues || (BauHauptGewerbeSektorEnumValues = {}));
var BauNebenGewerbeSektorEnum;
(function (BauNebenGewerbeSektorEnum) {
  BauNebenGewerbeSektorEnum["ASPHALTIERER"] = "ASPHALTIERER";
  BauNebenGewerbeSektorEnum["BAUMASCHINENVERLEIHER"] = "BAUMASCHINENVERLEIHER";
  BauNebenGewerbeSektorEnum["BAUSTOFFERZEUGER"] = "BAUSTOFFERZEUGER";
  BauNebenGewerbeSektorEnum["BAUWERKSABDICHTER"] = "BAUWERKSABDICHTER";
  BauNebenGewerbeSektorEnum["BETONWARENERZEUGER"] = "BETONWARENERZEUGER";
  BauNebenGewerbeSektorEnum["BODENLEGER"] = "BODENLEGER";
  BauNebenGewerbeSektorEnum["BRUNNENMEISTER"] = "BRUNNENMEISTER";
  BauNebenGewerbeSektorEnum["GERUESTVERLEIHER"] = "GERUESTVERLEIHER";
  BauNebenGewerbeSektorEnum["GIPSER"] = "GIPSER";
  BauNebenGewerbeSektorEnum["KALKBRENNEREIEN"] = "KALKBRENNEREIEN";
  BauNebenGewerbeSektorEnum["PFLASTERER"] = "PFLASTERER";
  BauNebenGewerbeSektorEnum["SPRENGUNGSUNTERNEHMER"] = "SPRENGUNGSUNTERNEHMER";
  BauNebenGewerbeSektorEnum["SANDKIESSCHOTTERERZEUGER"] = "SANDKIESSCHOTTERERZEUGER";
  BauNebenGewerbeSektorEnum["STEINBRUCHUNTERNEHMEN"] = "STEINBRUCHUNTERNEHMEN";
  BauNebenGewerbeSektorEnum["STUCKATEUREUNDTROCKENAUSBAUER"] = "STUCKATEUREUNDTROCKENAUSBAUER";
  BauNebenGewerbeSektorEnum["STEINMETZE"] = "STEINMETZE";
  BauNebenGewerbeSektorEnum["TIEFBOHRUNTERNEHMER"] = "TIEFBOHRUNTERNEHMER";
  BauNebenGewerbeSektorEnum["WAERMEKAELTESCHALLUNDBRANDDAMMER"] = "WAERMEKAELTESCHALLUNDBRANDDAMMER";
})(BauNebenGewerbeSektorEnum || (BauNebenGewerbeSektorEnum = {}));
var BauNebenGewerbeSektorEnumValues;
(function (BauNebenGewerbeSektorEnumValues) {
  function getLabelKeys() {
    return [{
      key: BauNebenGewerbeSektorEnum.ASPHALTIERER,
      label: 'constructionSectors.sideSector1'
    }, {
      key: BauNebenGewerbeSektorEnum.BAUMASCHINENVERLEIHER,
      label: 'constructionSectors.sideSector2'
    }, {
      key: BauNebenGewerbeSektorEnum.BAUSTOFFERZEUGER,
      label: 'constructionSectors.sideSector3'
    }, {
      key: BauNebenGewerbeSektorEnum.BAUWERKSABDICHTER,
      label: 'constructionSectors.sideSector4'
    }, {
      key: BauNebenGewerbeSektorEnum.BETONWARENERZEUGER,
      label: 'constructionSectors.sideSector5'
    }, {
      key: BauNebenGewerbeSektorEnum.BODENLEGER,
      label: 'constructionSectors.sideSector6'
    }, {
      key: BauNebenGewerbeSektorEnum.BRUNNENMEISTER,
      label: 'constructionSectors.sideSector7'
    }, {
      key: BauNebenGewerbeSektorEnum.GERUESTVERLEIHER,
      label: 'constructionSectors.sideSector8'
    }, {
      key: BauNebenGewerbeSektorEnum.GIPSER,
      label: 'constructionSectors.sideSector9'
    }, {
      key: BauNebenGewerbeSektorEnum.KALKBRENNEREIEN,
      label: 'constructionSectors.sideSector10'
    }, {
      key: BauNebenGewerbeSektorEnum.PFLASTERER,
      label: 'constructionSectors.sideSector11'
    }, {
      key: BauNebenGewerbeSektorEnum.SPRENGUNGSUNTERNEHMER,
      label: 'constructionSectors.sideSector12'
    }, {
      key: BauNebenGewerbeSektorEnum.SANDKIESSCHOTTERERZEUGER,
      label: 'constructionSectors.sideSector13'
    }, {
      key: BauNebenGewerbeSektorEnum.STEINBRUCHUNTERNEHMEN,
      label: 'constructionSectors.sideSector14'
    }, {
      key: BauNebenGewerbeSektorEnum.STUCKATEUREUNDTROCKENAUSBAUER,
      label: 'constructionSectors.sideSector15'
    }, {
      key: BauNebenGewerbeSektorEnum.STEINMETZE,
      label: 'constructionSectors.sideSector16'
    }, {
      key: BauNebenGewerbeSektorEnum.TIEFBOHRUNTERNEHMER,
      label: 'constructionSectors.sideSector17'
    }, {
      key: BauNebenGewerbeSektorEnum.WAERMEKAELTESCHALLUNDBRANDDAMMER,
      label: 'constructionSectors.sideSector18'
    }];
  }
  BauNebenGewerbeSektorEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  BauNebenGewerbeSektorEnumValues.getEnumByKey = getEnumByKey;
})(BauNebenGewerbeSektorEnumValues || (BauNebenGewerbeSektorEnumValues = {}));
var BauHilfsGewerbeSektorEnum;
(function (BauHilfsGewerbeSektorEnum) {
  BauHilfsGewerbeSektorEnum["ERSCHLIESSUNG_VON_GRUNDSTUECKEN_BAUTRAEGER"] = "ERSCHLIESSUNG_VON_GRUNDSTUECKEN_BAUTRAEGER";
  BauHilfsGewerbeSektorEnum["BAU_VON_GEBAEUDEN"] = "BAU_VON_GEBAEUDEN";
  BauHilfsGewerbeSektorEnum["BAU_VON_STRASSEN"] = "BAU_VON_STRASSEN";
  BauHilfsGewerbeSektorEnum["BAU_VON_BAHNVORKEHRSSTRICKEN"] = "BAU_VON_BAHNVORKEHRSSTRICKEN";
  BauHilfsGewerbeSektorEnum["BRUECKEN_UND_TUNNELBAU"] = "BRUECKEN_UND_TUNNELBAU";
  BauHilfsGewerbeSektorEnum["ROHRLEITUNGSTIEFBAU_BRUNNENBAU_UND_KLAERANLAGENBAU"] = "ROHRLEITUNGSTIEFBAU_BRUNNENBAU_UND_KLAERANLAGENBAU";
  BauHilfsGewerbeSektorEnum["KABELNETZLEITUNGSTIEFBAU"] = "KABELNETZLEITUNGSTIEFBAU";
  BauHilfsGewerbeSektorEnum["WASSERBAU"] = "WASSERBAU";
  BauHilfsGewerbeSektorEnum["SONSTIGER_TIEFBAU_ANG"] = "SONSTIGER_TIEFBAU_ANG";
  BauHilfsGewerbeSektorEnum["ABBRUCHARBEITEN"] = "ABBRUCHARBEITEN";
  BauHilfsGewerbeSektorEnum["VORBEREITENDE_BAUSTELLENARBEITEN"] = "VORBEREITENDE_BAUSTELLENARBEITEN";
  BauHilfsGewerbeSektorEnum["TEST_UND_SUCHBOHRUNG"] = "TEST_UND_SUCHBOHRUNG";
  BauHilfsGewerbeSektorEnum["ELEKTROINSTALLATION"] = "ELEKTROINSTALLATION";
  BauHilfsGewerbeSektorEnum["GAS_WASSER_HEIZUNG_UND_LUEFTUNG_UND_KLIMAANLAGENINSTALLATION"] = "GAS_WASSER_HEIZUNG_UND_LUEFTUNG_UND_KLIMAANLAGENINSTALLATION";
  BauHilfsGewerbeSektorEnum["SONSTIGE_BAUINSTALLATION"] = "SONSTIGE_BAUINSTALLATION";
  BauHilfsGewerbeSektorEnum["ANBRINGEN_VON_STUCKATUREN_GIPSEREI_UND_VERPUTZEREI"] = "ANBRINGEN_VON_STUCKATUREN_GIPSEREI_UND_VERPUTZEREI";
  BauHilfsGewerbeSektorEnum["BAUTISCHLEREI_UND_SCHLOSSEREI"] = "BAUTISCHLEREI_UND_SCHLOSSEREI";
  BauHilfsGewerbeSektorEnum["FUSSBODEN_FLIESEN_UND_PLATTENLEGEREI_TAPEZIEREREI"] = "FUSSBODEN_FLIESEN_UND_PLATTENLEGEREI_TAPEZIEREREI";
  BauHilfsGewerbeSektorEnum["MALEREI_UND_GLASEREI"] = "MALEREI_UND_GLASEREI";
  BauHilfsGewerbeSektorEnum["SONSTIGER_AUSBAU_ANG"] = "SONSTIGER_AUSBAU_ANG";
  BauHilfsGewerbeSektorEnum["DACHDECKEREI_UND_ZIMMEREREI"] = "DACHDECKEREI_UND_ZIMMEREREI";
  BauHilfsGewerbeSektorEnum["SONSTIGE_SPEZIALISIERTE_BAUTAETIGKEITEN_ANG"] = "SONSTIGE_SPEZIALISIERTE_BAUTAETIGKEITEN_ANG";
})(BauHilfsGewerbeSektorEnum || (BauHilfsGewerbeSektorEnum = {}));
var BauHilfsGewerbeSektorEnumValues;
(function (BauHilfsGewerbeSektorEnumValues) {
  function getLabelKeys() {
    return [{
      key: BauHilfsGewerbeSektorEnum.ERSCHLIESSUNG_VON_GRUNDSTUECKEN_BAUTRAEGER,
      label: 'constructionSectors.helpSector1'
    }, {
      key: BauHilfsGewerbeSektorEnum.BAU_VON_GEBAEUDEN,
      label: 'constructionSectors.helpSector2'
    }, {
      key: BauHilfsGewerbeSektorEnum.BAU_VON_STRASSEN,
      label: 'constructionSectors.helpSector3'
    }, {
      key: BauHilfsGewerbeSektorEnum.BAU_VON_BAHNVORKEHRSSTRICKEN,
      label: 'constructionSectors.helpSector4'
    }, {
      key: BauHilfsGewerbeSektorEnum.BRUECKEN_UND_TUNNELBAU,
      label: 'constructionSectors.helpSector5'
    }, {
      key: BauHilfsGewerbeSektorEnum.ROHRLEITUNGSTIEFBAU_BRUNNENBAU_UND_KLAERANLAGENBAU,
      label: 'constructionSectors.helpSector6'
    }, {
      key: BauHilfsGewerbeSektorEnum.KABELNETZLEITUNGSTIEFBAU,
      label: 'constructionSectors.helpSector7'
    }, {
      key: BauHilfsGewerbeSektorEnum.WASSERBAU,
      label: 'constructionSectors.helpSector8'
    }, {
      key: BauHilfsGewerbeSektorEnum.SONSTIGER_TIEFBAU_ANG,
      label: 'constructionSectors.helpSector9'
    }, {
      key: BauHilfsGewerbeSektorEnum.ABBRUCHARBEITEN,
      label: 'constructionSectors.helpSector10'
    }, {
      key: BauHilfsGewerbeSektorEnum.VORBEREITENDE_BAUSTELLENARBEITEN,
      label: 'constructionSectors.helpSector11'
    }, {
      key: BauHilfsGewerbeSektorEnum.TEST_UND_SUCHBOHRUNG,
      label: 'constructionSectors.helpSector12'
    }, {
      key: BauHilfsGewerbeSektorEnum.ELEKTROINSTALLATION,
      label: 'constructionSectors.helpSector13'
    }, {
      key: BauHilfsGewerbeSektorEnum.GAS_WASSER_HEIZUNG_UND_LUEFTUNG_UND_KLIMAANLAGENINSTALLATION,
      label: 'constructionSectors.helpSector14'
    }, {
      key: BauHilfsGewerbeSektorEnum.SONSTIGE_BAUINSTALLATION,
      label: 'constructionSectors.helpSector15'
    }, {
      key: BauHilfsGewerbeSektorEnum.ANBRINGEN_VON_STUCKATUREN_GIPSEREI_UND_VERPUTZEREI,
      label: 'constructionSectors.helpSector16'
    }, {
      key: BauHilfsGewerbeSektorEnum.BAUTISCHLEREI_UND_SCHLOSSEREI,
      label: 'constructionSectors.helpSector17'
    }, {
      key: BauHilfsGewerbeSektorEnum.FUSSBODEN_FLIESEN_UND_PLATTENLEGEREI_TAPEZIEREREI,
      label: 'constructionSectors.helpSector18'
    }, {
      key: BauHilfsGewerbeSektorEnum.MALEREI_UND_GLASEREI,
      label: 'constructionSectors.helpSector19'
    }, {
      key: BauHilfsGewerbeSektorEnum.SONSTIGER_AUSBAU_ANG,
      label: 'constructionSectors.helpSector20'
    }, {
      key: BauHilfsGewerbeSektorEnum.DACHDECKEREI_UND_ZIMMEREREI,
      label: 'constructionSectors.helpSector21'
    }, {
      key: BauHilfsGewerbeSektorEnum.SONSTIGE_SPEZIALISIERTE_BAUTAETIGKEITEN_ANG,
      label: 'constructionSectors.helpSector22'
    }];
  }
  BauHilfsGewerbeSektorEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  BauHilfsGewerbeSektorEnumValues.getEnumByKey = getEnumByKey;
})(BauHilfsGewerbeSektorEnumValues || (BauHilfsGewerbeSektorEnumValues = {}));
var ContractStatusEnum;
(function (ContractStatusEnum) {
  /**
   * Vertrag via Onboarding erstellt.
   */
  ContractStatusEnum["READY_FOR_INITIAL_CHECK"] = "READY_FOR_INITIAL_CHECK";
  /**
   * Onboarding-Daten durch Makler geprüft.
   */
  ContractStatusEnum["CONTRACT_REVIEWED_BY_BROKER"] = "CONTRACT_REVIEWED_BY_BROKER";
  /**
   * Vertrag manuell/händisch von Makler erstellt.
   */
  ContractStatusEnum["IN_CREATION"] = "IN_CREATION";
  /**
   * Versicherungs-Offert angefragt.
   */
  ContractStatusEnum["OFFERT_REQUESTED"] = "OFFERT_REQUESTED";
  /**
   * Versicherungs-Offert hochgeladen.
   */
  ContractStatusEnum["OFFERT_UPLOADED"] = "OFFERT_UPLOADED";
  /**
   * Um Versicherungs-Offert(e) an den Kunden senden zu können, benötigen wir ggf. noch weitere Informationen.
   */
  ContractStatusEnum["CUSTOMER_ACTION_REQUESTED"] = "CUSTOMER_ACTION_REQUESTED";
  /**
   * Kunde hat weitere Informationen übermittelt.
   */
  ContractStatusEnum["CUSTOMER_ACTION_PROVIDED"] = "CUSTOMER_ACTION_PROVIDED";
  /**
   * Versicherungs-Offert/Vergleich Unterschrift ggf. vom Kunden angefragt.
   */
  ContractStatusEnum["OFFERT_SIGNATURE_REQUESTED"] = "OFFERT_SIGNATURE_REQUESTED";
  /**
   * Kunde hat Versicherungs-Offert/Vergleich unterschrieben.
   */
  ContractStatusEnum["OFFERT_SIGNED_BY_CUSTOMER"] = "OFFERT_SIGNED_BY_CUSTOMER";
  /**
   * Versicherungs-Dokument Unterschrift ggf. vom Kunden angefragt.
   */
  ContractStatusEnum["CUSTOMER_SIGNATURE_REQUESTED"] = "CUSTOMER_SIGNATURE_REQUESTED";
  /**
   * Kunde hat Versicherungs-Dokument unterschrieben.
   */
  ContractStatusEnum["CUSTOMER_SIGNATURE_PROVIDED"] = "CUSTOMER_SIGNATURE_PROVIDED";
  /**
   * Versicherungs-Polizze wird angefragt.
   */
  ContractStatusEnum["POLIZZE_REQUESTED"] = "POLIZZE_REQUESTED";
  /**
   * Versicherungs-Polizze wurde hochgeladen.
   */
  ContractStatusEnum["POLIZZE_UPLOADED"] = "POLIZZE_UPLOADED";
  /**
   * Polizzierung beauftragt
   */
  ContractStatusEnum["FINISHED"] = "FINISHED";
})(ContractStatusEnum || (ContractStatusEnum = {}));
var ContractStatusEnumValues;
(function (ContractStatusEnumValues) {
  function getLabelKeys() {
    return [{
      key: ContractStatusEnum.READY_FOR_INITIAL_CHECK,
      label: 'contract.readyForInitialCheck'
    }, {
      key: ContractStatusEnum.CONTRACT_REVIEWED_BY_BROKER,
      label: 'contract.contractReviewedByBroker'
    }, {
      key: ContractStatusEnum.IN_CREATION,
      label: 'contract.inCreation'
    }, {
      key: ContractStatusEnum.OFFERT_REQUESTED,
      label: 'contract.offertRequested'
    }, {
      key: ContractStatusEnum.OFFERT_UPLOADED,
      label: 'contract.offertUploaded'
    }, {
      key: ContractStatusEnum.CUSTOMER_ACTION_REQUESTED,
      label: 'contract.customerActionRequested'
    }, {
      key: ContractStatusEnum.CUSTOMER_ACTION_PROVIDED,
      label: 'contract.customerActionProvided'
    }, {
      key: ContractStatusEnum.OFFERT_SIGNATURE_REQUESTED,
      label: 'contract.offertSignatureRequested'
    }, {
      key: ContractStatusEnum.OFFERT_SIGNED_BY_CUSTOMER,
      label: 'contract.signedByCustomer'
    }, {
      key: ContractStatusEnum.CUSTOMER_SIGNATURE_REQUESTED,
      label: 'contract.signatureRequested'
    }, {
      key: ContractStatusEnum.CUSTOMER_SIGNATURE_PROVIDED,
      label: 'contract.signatureProvided'
    }, {
      key: ContractStatusEnum.POLIZZE_REQUESTED,
      label: 'contract.polizzeRequested'
    }, {
      key: ContractStatusEnum.POLIZZE_UPLOADED,
      label: 'contract.polizzeUploaded'
    }, {
      key: ContractStatusEnum.FINISHED,
      label: 'contract.finished'
    }, {
      key: ContractStatusEnum.CUSTOMER_ACTION_REQUESTED,
      label: 'contract.customerActionRequested'
    }];
  }
  ContractStatusEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  ContractStatusEnumValues.getEnumByKey = getEnumByKey;
})(ContractStatusEnumValues || (ContractStatusEnumValues = {}));
var ContractCategoryEnum;
(function (ContractCategoryEnum) {
  ContractCategoryEnum["GO_PRODUCT"] = "GO_PRODUCT";
  ContractCategoryEnum["CALLIDUS"] = "CALLIDUS";
  ContractCategoryEnum["AUSSCHREIBUNG"] = "AUSSCHREIBUNG";
  ContractCategoryEnum["SERVO"] = "SERVO";
  ContractCategoryEnum["UNKNOWN"] = "UNKNOWN";
  ContractCategoryEnum["VERA"] = "VERA";
})(ContractCategoryEnum || (ContractCategoryEnum = {}));
function createBauHaftpflichtContractBase() {
  const bauHaftpflichtContractBase = {
    ...createContract(),
    bauHauptGewerbeSektoren: [],
    bauNebenGewerbeSektoren: [],
    bauHilfsGewerbeSektoren: [],
    limitedToSectors: null,
    annualSalesTotal: 0,
    annualSalesWithTrade: 0,
    customer: null
  };
  return bauHaftpflichtContractBase;
}
var ClaimStatusEnum;
(function (ClaimStatusEnum) {
  ClaimStatusEnum["PENDING"] = "PENDING";
  ClaimStatusEnum["OTHER"] = "OTHER";
  ClaimStatusEnum["GOODWILL"] = "GOODWILL";
  ClaimStatusEnum["WAITING"] = "WAITING";
  ClaimStatusEnum["LAWYER"] = "LAWYER";
  ClaimStatusEnum["IN_ACTION"] = "IN_ACTION";
  ClaimStatusEnum["CLOSED_WITHOUT_PAYMENT"] = "CLOSED_WITHOUT_PAYMENT";
  ClaimStatusEnum["DECLINED"] = "DECLINED";
  ClaimStatusEnum["DONE"] = "DONE";
  ClaimStatusEnum["DOUBLE_CREATED"] = "DOUBLE_CREATED";
  ClaimStatusEnum["IN_CLARIFICATION"] = "IN_CLARIFICATION";
})(ClaimStatusEnum || (ClaimStatusEnum = {}));
var ClaimStatusEnumValues;
(function (ClaimStatusEnumValues) {
  function getLabelKeys() {
    return [{
      key: ClaimStatusEnum.PENDING,
      label: 'claim.statusPending'
    }, {
      key: ClaimStatusEnum.OTHER,
      label: 'claim.statusOther'
    }, {
      key: ClaimStatusEnum.GOODWILL,
      label: 'claim.statusGoodwill'
    }, {
      key: ClaimStatusEnum.WAITING,
      label: 'claim.statusWaiting'
    }, {
      key: ClaimStatusEnum.LAWYER,
      label: 'claim.statusLawyer'
    }, {
      key: ClaimStatusEnum.IN_ACTION,
      label: 'claim.statusInAction'
    }, {
      key: ClaimStatusEnum.CLOSED_WITHOUT_PAYMENT,
      label: 'claim.statusClosedWithoutPayment'
    }, {
      key: ClaimStatusEnum.DECLINED,
      label: 'claim.statusDeclined'
    }, {
      key: ClaimStatusEnum.DONE,
      label: 'claim.statusDone'
    }, {
      key: ClaimStatusEnum.DOUBLE_CREATED,
      label: 'claim.statusDoubleCreated'
    }, {
      key: ClaimStatusEnum.IN_CLARIFICATION,
      label: 'claim.statusInClarification'
    }];
  }
  ClaimStatusEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  ClaimStatusEnumValues.getEnumByKey = getEnumByKey;
})(ClaimStatusEnumValues || (ClaimStatusEnumValues = {}));
var BrokernetDocumentTypeEnum;
(function (BrokernetDocumentTypeEnum) {
  BrokernetDocumentTypeEnum["HAFTUNGSEINSCHRAENKUNG"] = "HAFTUNGSEINSCHRAENKUNG";
  BrokernetDocumentTypeEnum["VOLLMACHTSVEREINBARUNG"] = "VOLLMACHTSVEREINBARUNG";
  BrokernetDocumentTypeEnum["MAKLERVERTRAG_COMPANY"] = "MAKLERVERTRAG_COMPANY";
  BrokernetDocumentTypeEnum["MAKLERVERTRAG_PERSON"] = "MAKLERVERTRAG_PERSON";
  BrokernetDocumentTypeEnum["HOTELKONZEPT_ANGEBOT"] = "HOTELKONZEPT_ANGEBOT";
  /**
   * The SVA Preview-Data for Offert Upload.
   */
  BrokernetDocumentTypeEnum["SVA"] = "SVA";
  BrokernetDocumentTypeEnum["SVA_VERGLEICH"] = "SVA_VERGLEICH";
  /**
   * The HVA Preview-Data for Offert Upload.
   */
  BrokernetDocumentTypeEnum["HVA"] = "HVA";
  BrokernetDocumentTypeEnum["HVA_VERGLEICH"] = "HVA_VERGLEICH";
  BrokernetDocumentTypeEnum["FIRMENBUCHAUSZUG"] = "FIRMENBUCHAUSZUG";
  BrokernetDocumentTypeEnum["EXCEDENT_ORIGINAL_POLIZZE"] = "EXCEDENT_ORIGINAL_POLIZZE";
  BrokernetDocumentTypeEnum["EXCEDENT_BEDINGUNGEN"] = "EXCEDENT_BEDINGUNGEN";
  BrokernetDocumentTypeEnum["EXCEDENT_KLAUSELPAKET"] = "EXCEDENT_KLAUSELPAKET";
  BrokernetDocumentTypeEnum["OFFERT"] = "OFFERT";
  BrokernetDocumentTypeEnum["PRAEMIEN_SCHADENSAUFSTELLUNG"] = "PRAEMIEN_SCHADENSAUFSTELLUNG";
  BrokernetDocumentTypeEnum["POLIZZIERUNGS_AUFTRAG"] = "POLIZZIERUNGS_AUFTRAG";
  BrokernetDocumentTypeEnum["BERATUNGSPROTOKOLL_KUNDE"] = "BERATUNGSPROTOKOLL_KUNDE";
  /**
   * Hochwasserkarte in SVA
   **/
  BrokernetDocumentTypeEnum["HQ_MAP"] = "HQ_MAP";
  BrokernetDocumentTypeEnum["GEWERBEKLAUSELN_AUSSCHREIBUNG"] = "GEWERBEKLAUSELN_AUSSCHREIBUNG";
  /**
   * The BVS Preview-Data file. (Haftpflicht)
   */
  BrokernetDocumentTypeEnum["BROKER_PREVIEW_BVS_HAFTPFLICHT"] = "BROKER_PREVIEW_BVS_HAFTPFLICHT";
  /**
   * The BVS Preview-Data file. (Haftpflicht und Kasko)
   */
  BrokernetDocumentTypeEnum["BROKER_PREVIEW_BVS_HAFTPFLICHT_UND_KASKO"] = "BROKER_PREVIEW_BVS_HAFTPFLICHT_UND_KASKO";
  /**
   * The customer signed BVS Preview-Data file. (Haftpflicht)
   */
  BrokernetDocumentTypeEnum["CUSTOMER_BVS_HAFTPFLICHT"] = "CUSTOMER_BVS_HAFTPFLICHT";
  /**
   * The customer signed BVS Preview-Data file. (Haftpflicht und Kasko)
   */
  BrokernetDocumentTypeEnum["CUSTOMER_BVS_HAFTPFLICHT_UND_KASKO"] = "CUSTOMER_BVS_HAFTPFLICHT_UND_KASKO";
  /**
   * The Charter-Kautionsversicherung Preview-Data file.
   */
  BrokernetDocumentTypeEnum["BROKER_PREVIEW_CHARTER"] = "BROKER_PREVIEW_CHARTER";
  /**
   * The customer signed Charter-Kautionsversicherung Preview-Data file.
   */
  BrokernetDocumentTypeEnum["CUSTOMER_CHARTER"] = "CUSTOMER_CHARTER";
  /**
   * The Skipper-Haftpflicht Broker Preview-Data file.
   */
  BrokernetDocumentTypeEnum["BROKER_PREVIEW_SKIPPER_HAFTPFLICHT"] = "BROKER_PREVIEW_SKIPPER_HAFTPFLICHT";
  /**
   * The Skipper-Rechtsschutz Broker Preview-Data file.
   */
  BrokernetDocumentTypeEnum["BROKER_PREVIEW_SKIPPER_RECHTSSCHUTZ"] = "BROKER_PREVIEW_SKIPPER_RECHTSSCHUTZ";
  /**
   * The customer signed Skipper-Haftpflicht Preview-Data file.
   */
  BrokernetDocumentTypeEnum["CUSTOMER_SKIPPER_HAFTPFLICHT"] = "CUSTOMER_SKIPPER_HAFTPFLICHT";
  /**
   * The customer signed Skipper-Rechtsschutz Preview-Data file.
   */
  BrokernetDocumentTypeEnum["CUSTOMER_SKIPPER_RECHTSSCHUTZ"] = "CUSTOMER_SKIPPER_RECHTSSCHUTZ";
  BrokernetDocumentTypeEnum["CUSTOMER_SIGNED_SERVO_KAUFPREIS_KASKO"] = "CUSTOMER_SIGNED_SERVO_KAUFPREIS_KASKO";
  BrokernetDocumentTypeEnum["CUSTOMER_PREVIEW_SERVO_KAUFPREIS_KASKO"] = "CUSTOMER_PREVIEW_SERVO_KAUFPREIS_KASKO";
  BrokernetDocumentTypeEnum["CUSTOMER_SIGNED_SERVO_GARANTIE"] = "CUSTOMER_SIGNED_SERVO_GARANTIE";
  BrokernetDocumentTypeEnum["CUSTOMER_PREVIEW_SERVO_GARANTIE"] = "CUSTOMER_PREVIEW_SERVO_GARANTIE";
  BrokernetDocumentTypeEnum["CUSTOMER_SIGNED_SERVO_ELEKTRONIK"] = "CUSTOMER_SIGNED_SERVO_ELEKTRONIK";
  BrokernetDocumentTypeEnum["CUSTOMER_PREVIEW_SERVO_ELEKTRONIK"] = "CUSTOMER_PREVIEW_SERVO_ELEKTRONIK";
  BrokernetDocumentTypeEnum["CUSTOMER_SIGNED_SERVO_BIKE"] = "CUSTOMER_SIGNED_SERVO_BIKE";
  BrokernetDocumentTypeEnum["CUSTOMER_PREVIEW_SERVO_BIKE"] = "CUSTOMER_PREVIEW_SERVO_BIKE";
  /**
   * The final, completed and accepted insurance polizze
   */
  BrokernetDocumentTypeEnum["POLIZZE"] = "POLIZZE";
  BrokernetDocumentTypeEnum["RECHNUNG"] = "RECHNUNG";
  BrokernetDocumentTypeEnum["KAUFVERTRAG"] = "KAUFVERTRAG";
  BrokernetDocumentTypeEnum["SONSTIGES"] = "SONSTIGES";
  /**
   * Eingemeldetes Schadens-Bild.
   */
  BrokernetDocumentTypeEnum["CLAIM_PICTURE"] = "CLAIM_PICTURE";
  /**
   * Eingemeldetes Schadens-Dokument.
   */
  BrokernetDocumentTypeEnum["CLAIM_DOCUMENT"] = "CLAIM_DOCUMENT";
  /**
   * Bilder welche im Chat gesendet werden
   */
  BrokernetDocumentTypeEnum["CHAT_PICTURE"] = "CHAT_PICTURE";
  /**
   * Dokumente welche im Chat gesendet werden
   */
  BrokernetDocumentTypeEnum["CHAT_DOCUMENT"] = "CHAT_DOCUMENT";
  BrokernetDocumentTypeEnum["IPID"] = "IPID";
  BrokernetDocumentTypeEnum["AVB"] = "AVB";
})(BrokernetDocumentTypeEnum || (BrokernetDocumentTypeEnum = {}));
var BrokernetDocumentTypeEnumValues;
(function (BrokernetDocumentTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: BrokernetDocumentTypeEnum.HAFTUNGSEINSCHRAENKUNG,
      label: 'customer.haftungseinschraenkung'
    }, {
      key: BrokernetDocumentTypeEnum.VOLLMACHTSVEREINBARUNG,
      label: 'customer.vollmacht'
    }, {
      key: BrokernetDocumentTypeEnum.MAKLERVERTRAG_COMPANY,
      label: 'customer.maklervertragCompany'
    }, {
      key: BrokernetDocumentTypeEnum.MAKLERVERTRAG_PERSON,
      label: 'customer.maklervertragPerson'
    }, {
      key: BrokernetDocumentTypeEnum.HOTELKONZEPT_ANGEBOT,
      label: 'customer.hotelkonzeptAngebot'
    }, {
      key: BrokernetDocumentTypeEnum.SVA,
      label: 'contract.sva'
    }, {
      key: BrokernetDocumentTypeEnum.SVA_VERGLEICH,
      label: 'customer.svaComparison'
    }, {
      key: BrokernetDocumentTypeEnum.HVA,
      label: 'contract.hva'
    }, {
      key: BrokernetDocumentTypeEnum.HVA_VERGLEICH,
      label: 'customer.hvaComparison'
    }, {
      key: BrokernetDocumentTypeEnum.FIRMENBUCHAUSZUG,
      label: 'customer.firmenbuchauszug'
    }, {
      key: BrokernetDocumentTypeEnum.EXCEDENT_ORIGINAL_POLIZZE,
      label: 'documents.docTypeOrignalPolicy'
    }, {
      key: BrokernetDocumentTypeEnum.EXCEDENT_BEDINGUNGEN,
      label: 'documents.docTypeConditions'
    }, {
      key: BrokernetDocumentTypeEnum.EXCEDENT_KLAUSELPAKET,
      label: 'documents.docTypeKlauseln'
    }, {
      key: BrokernetDocumentTypeEnum.OFFERT,
      label: 'customer.offer'
    }, {
      key: BrokernetDocumentTypeEnum.PRAEMIEN_SCHADENSAUFSTELLUNG,
      label: 'documents.docTypePraemie'
    }, {
      key: BrokernetDocumentTypeEnum.POLIZZIERUNGS_AUFTRAG,
      label: 'documents.polizzierungAuftrag'
    }, {
      key: BrokernetDocumentTypeEnum.BERATUNGSPROTOKOLL_KUNDE,
      label: 'documents.beratungsprotokoll'
    }, {
      key: BrokernetDocumentTypeEnum.HQ_MAP,
      label: 'documents.hqMap'
    }, {
      key: BrokernetDocumentTypeEnum.GEWERBEKLAUSELN_AUSSCHREIBUNG,
      label: 'documents.gewerbeklauselnAusschreibung'
    }, {
      key: BrokernetDocumentTypeEnum.BROKER_PREVIEW_BVS_HAFTPFLICHT,
      label: 'documents.bvs.haftpflicht.brokerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.BROKER_PREVIEW_BVS_HAFTPFLICHT_UND_KASKO,
      label: 'documents.bvs.haftpflichtKasko.brokerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_BVS_HAFTPFLICHT,
      label: 'documents.bvs.haftpflicht.customerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_BVS_HAFTPFLICHT_UND_KASKO,
      label: 'documents.bvs.haftpflichtKasko.customerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.BROKER_PREVIEW_CHARTER,
      label: 'documents.charter.brokerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_CHARTER,
      label: 'documents.charter.customerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.BROKER_PREVIEW_SKIPPER_HAFTPFLICHT,
      label: 'documents.skipper.haftpflicht.brokerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.BROKER_PREVIEW_SKIPPER_RECHTSSCHUTZ,
      label: 'documents.skipper.rechtsschutz.brokerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_SKIPPER_HAFTPFLICHT,
      label: 'documents.skipper.haftpflicht.customerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_SKIPPER_RECHTSSCHUTZ,
      label: 'documents.skipper.rechtsschutz.customerPreview'
    }, {
      key: BrokernetDocumentTypeEnum.POLIZZE,
      label: 'documents.polizze'
    }, {
      key: BrokernetDocumentTypeEnum.RECHNUNG,
      label: 'documents.rechnung'
    }, {
      key: BrokernetDocumentTypeEnum.KAUFVERTRAG,
      label: 'documents.kaufvertrag'
    }, {
      key: BrokernetDocumentTypeEnum.SONSTIGES,
      label: 'documents.sonstiges'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_SIGNED_SERVO_KAUFPREIS_KASKO,
      label: 'documents.kaufpreisKaskoSigned'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_PREVIEW_SERVO_KAUFPREIS_KASKO,
      label: 'documents.kaufpreisKaskoPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_SIGNED_SERVO_GARANTIE,
      label: 'documents.garantieSigned'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_PREVIEW_SERVO_GARANTIE,
      label: 'documents.garantiePreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_SIGNED_SERVO_ELEKTRONIK,
      label: 'documents.techSigned'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_PREVIEW_SERVO_ELEKTRONIK,
      label: 'documents.techPreview'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_SIGNED_SERVO_BIKE,
      label: 'documents.bikeSigned'
    }, {
      key: BrokernetDocumentTypeEnum.CUSTOMER_PREVIEW_SERVO_BIKE,
      label: 'documents.bikePreview'
    }, {
      key: BrokernetDocumentTypeEnum.CLAIM_PICTURE,
      label: 'documents.claimPicture'
    }, {
      key: BrokernetDocumentTypeEnum.CLAIM_DOCUMENT,
      label: 'documents.claimDocument'
    }, {
      key: BrokernetDocumentTypeEnum.CHAT_DOCUMENT,
      label: 'documents.chatDocument'
    }, {
      key: BrokernetDocumentTypeEnum.CHAT_PICTURE,
      label: 'documents.chatPicture'
    }, {
      key: BrokernetDocumentTypeEnum.IPID,
      label: 'documents.ipid'
    }, {
      key: BrokernetDocumentTypeEnum.AVB,
      label: 'documents.avb'
    }];
  }
  BrokernetDocumentTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  BrokernetDocumentTypeEnumValues.getEnumByKey = getEnumByKey;
})(BrokernetDocumentTypeEnumValues || (BrokernetDocumentTypeEnumValues = {}));
var AdditionalInsuranceEnum;
(function (AdditionalInsuranceEnum) {
  AdditionalInsuranceEnum["RECHTSSCHUTZ"] = "RECHTSSCHUTZ";
  AdditionalInsuranceEnum["GARANTIEVERSICHERUNG"] = "GARANTIEVERSICHERUNG";
  AdditionalInsuranceEnum["REISEVERSICHERUNG"] = "REISEVERSICHERUNG";
  AdditionalInsuranceEnum["KRANKENVERSICHERUNG"] = "KRANKENVERSICHERUNG";
  AdditionalInsuranceEnum["KFZ"] = "KFZ";
  AdditionalInsuranceEnum["ELEMENTARVERSICHERUNG"] = "ELEMENTARVERSICHERUNG";
  AdditionalInsuranceEnum["KOLLEKTIVUNFALL_LEBEN"] = "KOLLEKTIVUNFALL_LEBEN";
  AdditionalInsuranceEnum["CYBERVERSICHERUNG"] = "CYBERVERSICHERUNG";
  AdditionalInsuranceEnum["EMPLOYEE"] = "EMPLOYEE";
  AdditionalInsuranceEnum["CEO"] = "CEO";
  AdditionalInsuranceEnum["DO"] = "DO";
  AdditionalInsuranceEnum["DEVICE"] = "DEVICE";
  AdditionalInsuranceEnum["AFFINITY"] = "AFFINITY";
})(AdditionalInsuranceEnum || (AdditionalInsuranceEnum = {}));
var OnboardingStatusEnum;
(function (OnboardingStatusEnum) {
  OnboardingStatusEnum["STARTED_BY_USER"] = "STARTED_BY_USER";
  OnboardingStatusEnum["INVITE_SENT"] = "INVITE_SENT";
  OnboardingStatusEnum["READY_FOR_CHECK"] = "READY_FOR_CHECK";
  OnboardingStatusEnum["DONE"] = "DONE";
})(OnboardingStatusEnum || (OnboardingStatusEnum = {}));
var PaymentPeriodEnum;
(function (PaymentPeriodEnum) {
  PaymentPeriodEnum["MONTHLY"] = "MONTHLY";
  PaymentPeriodEnum["QUARTER"] = "QUARTER";
  PaymentPeriodEnum["HALFYEAR"] = "HALFYEAR";
  PaymentPeriodEnum["YEARLY"] = "YEARLY";
})(PaymentPeriodEnum || (PaymentPeriodEnum = {}));
var PaymentPeriodEnumValues;
(function (PaymentPeriodEnumValues) {
  function getLabelKeys() {
    return [{
      key: PaymentPeriodEnum.MONTHLY,
      label: 'customer.paymentMonthly'
    }, {
      key: PaymentPeriodEnum.QUARTER,
      label: 'customer.paymentQuarter'
    }, {
      key: PaymentPeriodEnum.HALFYEAR,
      label: 'customer.paymentHalfYear'
    }, {
      key: PaymentPeriodEnum.YEARLY,
      label: 'customer.paymentYearly'
    }];
  }
  PaymentPeriodEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  PaymentPeriodEnumValues.getEnumByKey = getEnumByKey;
})(PaymentPeriodEnumValues || (PaymentPeriodEnumValues = {}));
var PaymentTypeEnum;
(function (PaymentTypeEnum) {
  PaymentTypeEnum["SEPA"] = "SEPA";
  PaymentTypeEnum["PAYMENTSLIP"] = "PAYMENTSLIP";
})(PaymentTypeEnum || (PaymentTypeEnum = {}));
var UserPermissionEnum;
(function (UserPermissionEnum) {
  /** general access to brokernet cockpit */
  UserPermissionEnum["BROKERNET_ACCESS"] = "BROKERNET_ACCESS";
  /** add new users */
  UserPermissionEnum["ADD_USERS"] = "ADD_USERS";
  /** read the users of a tenant */
  UserPermissionEnum["READ_USERS"] = "READ_USERS";
  /** add new tenants */
  UserPermissionEnum["ADD_TENANTS"] = "ADD_TENANTS";
  /** read all tenants */
  UserPermissionEnum["READ_TENANTS"] = "READ_TENANTS";
  /** global stats dashboard */
  UserPermissionEnum["GLOBAL_STATS"] = "GLOBAL_STATS";
  /** can create contracts of type BAU-HAFTPFLICHT */
  UserPermissionEnum["CONTRACT_BAU_HAFTPFLICHT"] = "CONTRACT_BAU_HAFTPFLICHT";
  /** can create contracts of type BAU-HAFTPFLICHT EXCEDENT */
  UserPermissionEnum["CONTRACT_BAU_HAFTPFLICHT_EXCEDENT"] = "CONTRACT_BAU_HAFTPFLICHT_EXCEDENT";
  /** can create contracts of type HOTEL */
  UserPermissionEnum["CONTRACT_HOTEL"] = "CONTRACT_HOTEL";
  /** can create contracts for PRIVATE */
  UserPermissionEnum["CONTRACT_PRIVATE"] = "CONTRACT_PRIVATE";
  /** presentation of the Hotelkonzept */
  UserPermissionEnum["HOTELKONZEPT_PRESENTATION"] = "HOTELKONZEPT_PRESENTATION";
  /** can create contracts of SVA */
  UserPermissionEnum["CONTRACT_SVA"] = "CONTRACT_SVA";
  /** can create contracts of HVA */
  UserPermissionEnum["CONTRACT_HVA"] = "CONTRACT_HVA";
  /** can access developer features */
  UserPermissionEnum["DEVELOPER"] = "DEVELOPER";
  /** connection to firmenbuchabfrage.at */
  UserPermissionEnum["FIRMENBUCH_ABFRAGE"] = "FIRMENBUCH_ABFRAGE";
  /** connection to vera-connector */
  UserPermissionEnum["VERA_CONNECTION"] = "VERA_CONNECTION";
  /** can edit the params for the BVS calculators */
  UserPermissionEnum["BVS_CALCULATION_VALUES_EDIT"] = "BVS_CALCULATION_VALUES_EDIT";
  /** can view the risk list of a customer */
  UserPermissionEnum["CUSTOMER_RISKLIST"] = "CUSTOMER_RISKLIST";
  /** can view the wishes and needs of a customer */
  UserPermissionEnum["CUSTOMER_WISHES_AND_NEEDS"] = "CUSTOMER_WISHES_AND_NEEDS";
  /** can view Honorar and Maklervertrag of a customer */
  UserPermissionEnum["CUSTOMER_HONORAR_MAKLERVERTRAG"] = "CUSTOMER_HONORAR_MAKLERVERTRAG";
  /** can view Investitionen of a customer */
  UserPermissionEnum["CUSTOMER_INVESTMENTS"] = "CUSTOMER_INVESTMENTS";
  /** can view Jahresgespräch of a customer */
  UserPermissionEnum["CUSTOMER_JAHRESZAHLEN"] = "CUSTOMER_JAHRESZAHLEN";
  /** can add company customers */
  UserPermissionEnum["ADD_COMPANY_CUSTOMER"] = "ADD_COMPANY_CUSTOMER";
  /** can add private customers */
  UserPermissionEnum["ADD_PRIVATE_CUSTOMER"] = "ADD_PRIVATE_CUSTOMER";
  /** can add servo elektronik contract */
  UserPermissionEnum["CONTRACT_SERVO_ELEKTRONIK"] = "CONTRACT_SERVO_ELEKTRONIK";
  /** can add servo garantie contract */
  UserPermissionEnum["CONTRACT_SERVO_GARANTIEVERLAENGERUNG"] = "CONTRACT_SERVO_GARANTIEVERLAENGERUNG";
  /** can add servo bike contract */
  UserPermissionEnum["CONTRACT_SERVO_BIKE_E_BIKE"] = "CONTRACT_SERVO_BIKE_E_BIKE";
  /** can add servo kaufpreis contract */
  UserPermissionEnum["CONTRACT_SERVO_KAUFPREIS_KASKO"] = "CONTRACT_SERVO_KAUFPREIS_KASKO";
  /** can create contracts of type BVS_HAFTPFLICHT */
  UserPermissionEnum["CONTRACT_BVS_HAFTPFLICHT"] = "CONTRACT_BVS_HAFTPFLICHT";
  /** can create contracts of type CONTRACT_BVS_HAFTPFLICHT_KASKO */
  UserPermissionEnum["CONTRACT_BVS_HAFTPFLICHT_KASKO"] = "CONTRACT_BVS_HAFTPFLICHT_KASKO";
  /** can create contracts of type SKIPPER_HAFTPFLICHT */
  UserPermissionEnum["CONTRACT_SKIPPER_HAFTPFLICHT"] = "CONTRACT_SKIPPER_HAFTPFLICHT";
  /** can create contracts of type SKIPPER_RECHTSSCHUTZ */
  UserPermissionEnum["CONTRACT_SKIPPER_RECHTSSCHUTZ"] = "CONTRACT_SKIPPER_RECHTSSCHUTZ";
  /** can create contracts of type CONTRACT_CHARTER */
  UserPermissionEnum["CONTRACT_CHARTER_KAUTION"] = "CONTRACT_CHARTER_KAUTION";
  /** can access chats */
  UserPermissionEnum["STELLA_CHAT"] = "STELLA_CHAT";
  /**
   * general access to brokernet app (Stella-APP)
   */
  UserPermissionEnum["STELLA_ACCESS"] = "STELLA_ACCESS";
  /**
   * Customer can send/read chat messages to/from Broker. (Stella-APP)
   */
})(UserPermissionEnum || (UserPermissionEnum = {}));
var BrokernetLanguageTypeEnum;
(function (BrokernetLanguageTypeEnum) {
  BrokernetLanguageTypeEnum["DE"] = "DE";
  BrokernetLanguageTypeEnum["EN"] = "EN";
})(BrokernetLanguageTypeEnum || (BrokernetLanguageTypeEnum = {}));
var BrokernetLanguageTypeValues;
(function (BrokernetLanguageTypeValues) {
  function getAll() {
    return [{
      key: BrokernetLanguageTypeEnum.DE,
      label: 'shared.german'
    }, {
      key: BrokernetLanguageTypeEnum.EN,
      label: 'shared.english'
    }];
  }
  BrokernetLanguageTypeValues.getAll = getAll;
  function getEnumByKey(key) {
    return getAll().find(k => k.key === key);
  }
  BrokernetLanguageTypeValues.getEnumByKey = getEnumByKey;
  function getAllEnumKeys() {
    return [BrokernetLanguageTypeEnum.DE, BrokernetLanguageTypeEnum.EN];
  }
  BrokernetLanguageTypeValues.getAllEnumKeys = getAllEnumKeys;
})(BrokernetLanguageTypeValues || (BrokernetLanguageTypeValues = {}));
var InsuranceCompanyNameEnum;
(function (InsuranceCompanyNameEnum) {
  InsuranceCompanyNameEnum["ALLIANZ"] = "ALLIANZ";
  InsuranceCompanyNameEnum["ARAG"] = "ARAG";
  InsuranceCompanyNameEnum["DIALOG"] = "DIALOG";
  InsuranceCompanyNameEnum["DONAU"] = "DONAU";
  InsuranceCompanyNameEnum["ERGO"] = "ERGO";
  InsuranceCompanyNameEnum["GENERALI"] = "GENERALI";
  InsuranceCompanyNameEnum["HDI"] = "HDI";
  InsuranceCompanyNameEnum["MERKUR"] = "MERKUR";
  InsuranceCompanyNameEnum["MUKI"] = "MUKI";
  InsuranceCompanyNameEnum["NOE_VS"] = "NOE_VS";
  InsuranceCompanyNameEnum["OOE_VS"] = "OOE_VS";
  InsuranceCompanyNameEnum["UNIQA"] = "UNIQA";
  InsuranceCompanyNameEnum["VAV"] = "VAV";
  InsuranceCompanyNameEnum["VIG"] = "VIG";
  InsuranceCompanyNameEnum["WUESTENROT"] = "WUESTENROT";
  InsuranceCompanyNameEnum["ZURICH"] = "ZURICH";
  InsuranceCompanyNameEnum["SERVO"] = "SERVO";
  InsuranceCompanyNameEnum["CALLIDUS"] = "CALLIDUS";
  InsuranceCompanyNameEnum["GRAWE"] = "GRAWE";
  InsuranceCompanyNameEnum["HELVETIA"] = "HELVETIA";
})(InsuranceCompanyNameEnum || (InsuranceCompanyNameEnum = {}));
var InsuranceCompanyNameEnumValues;
(function (InsuranceCompanyNameEnumValues) {
  function getLabelKeys() {
    return [{
      key: InsuranceCompanyNameEnum.ALLIANZ,
      label: 'insuranceCompanies.ALLIANZ'
    }, {
      key: InsuranceCompanyNameEnum.ARAG,
      label: 'insuranceCompanies.ARAG'
    }, {
      key: InsuranceCompanyNameEnum.DIALOG,
      label: 'insuranceCompanies.DIALOG'
    }, {
      key: InsuranceCompanyNameEnum.DONAU,
      label: 'insuranceCompanies.DONAU'
    }, {
      key: InsuranceCompanyNameEnum.ERGO,
      label: 'insuranceCompanies.ERGO'
    }, {
      key: InsuranceCompanyNameEnum.GENERALI,
      label: 'insuranceCompanies.GENERALI'
    }, {
      key: InsuranceCompanyNameEnum.HDI,
      label: 'insuranceCompanies.HDI'
    }, {
      key: InsuranceCompanyNameEnum.MERKUR,
      label: 'insuranceCompanies.MERKUR'
    }, {
      key: InsuranceCompanyNameEnum.MUKI,
      label: 'insuranceCompanies.MUKI'
    }, {
      key: InsuranceCompanyNameEnum.NOE_VS,
      label: 'insuranceCompanies.NOE_VS'
    }, {
      key: InsuranceCompanyNameEnum.OOE_VS,
      label: 'insuranceCompanies.OOE_VS'
    }, {
      key: InsuranceCompanyNameEnum.UNIQA,
      label: 'insuranceCompanies.UNIQA'
    }, {
      key: InsuranceCompanyNameEnum.VAV,
      label: 'insuranceCompanies.VAV'
    }, {
      key: InsuranceCompanyNameEnum.VIG,
      label: 'insuranceCompanies.VIG'
    }, {
      key: InsuranceCompanyNameEnum.WUESTENROT,
      label: 'insuranceCompanies.WUESTENROT'
    }, {
      key: InsuranceCompanyNameEnum.ZURICH,
      label: 'insuranceCompanies.ZURICH'
    }, {
      key: InsuranceCompanyNameEnum.SERVO,
      label: 'insuranceCompanies.SERVO'
    }, {
      key: InsuranceCompanyNameEnum.CALLIDUS,
      label: 'insuranceCompanies.CALLIDUS'
    }, {
      key: InsuranceCompanyNameEnum.GRAWE,
      label: 'insuranceCompanies.GRAWE'
    }, {
      key: InsuranceCompanyNameEnum.HELVETIA,
      label: 'insuranceCompanies.HELVETIA'
    }];
  }
  InsuranceCompanyNameEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  InsuranceCompanyNameEnumValues.getEnumByKey = getEnumByKey;
})(InsuranceCompanyNameEnumValues || (InsuranceCompanyNameEnumValues = {}));
var TrackingTypeEnum;
(function (TrackingTypeEnum) {
  TrackingTypeEnum["ONBOARDING_SUBMITTED"] = "ONBOARDING_SUBMITTED";
  TrackingTypeEnum["CONTRACT_REVIEWED"] = "CONTRACT_REVIEWED";
  TrackingTypeEnum["EXC_OFFERT_REQUESTED"] = "EXC_OFFERT_REQUESTED";
  TrackingTypeEnum["EXC_OFFERT_UPLOADED"] = "EXC_OFFERT_UPLOADED";
  TrackingTypeEnum["EXC_FINISHED"] = "EXC_FINISHED";
  TrackingTypeEnum["BAU_EXC_OFFERT_REQUESTED"] = "BAU_EXC_OFFERT_REQUESTED";
  TrackingTypeEnum["BAU_EXC_OFFERT_UPLOADED"] = "BAU_EXC_OFFERT_UPLOADED";
  TrackingTypeEnum["BAU_EXC_FINISHED"] = "BAU_EXC_FINISHED";
  TrackingTypeEnum["HOTEL_OFFERT_REQUESTED"] = "HOTEL_OFFERT_REQUESTED";
  TrackingTypeEnum["HOTEL_OFFERT_UPLOADED"] = "HOTEL_OFFERT_UPLOADED";
  TrackingTypeEnum["HOTEL_FINISHED"] = "HOTEL_FINISHED";
  TrackingTypeEnum["CONTACT_CONSENT_GIVEN"] = "CONTACT_CONSENT_GIVEN";
  TrackingTypeEnum["GENERAL_INSURANCE_CONDITIONS_ACCEPTED"] = "GENERAL_INSURANCE_CONDITIONS_ACCEPTED";
  TrackingTypeEnum["HOTEL_FOLDER_ACCEPTED"] = "HOTEL_FOLDER_ACCEPTED";
  TrackingTypeEnum["VOLLMACHT_SENT_TO_VARIAS"] = "VOLLMACHT_SENT_TO_VARIAS";
  TrackingTypeEnum["MAKLERVERTRAG_SENT_TO_VARIAS"] = "MAKLERVERTRAG_SENT_TO_VARIAS";
  TrackingTypeEnum["VOLLMACHT_SIGNATURE_REQUESTED_VIA_MAIL"] = "VOLLMACHT_SIGNATURE_REQUESTED_VIA_MAIL";
  TrackingTypeEnum["MAKLERVERTRAG_SIGNATURE_REQUESTED_VIA_MAIL"] = "MAKLERVERTRAG_SIGNATURE_REQUESTED_VIA_MAIL";
  TrackingTypeEnum["VOLLMACHT_SIGNATURE_REQUESTED_VIA_STELLA"] = "VOLLMACHT_SIGNATURE_REQUESTED_VIA_STELLA";
  TrackingTypeEnum["MAKLERVERTRAG_SIGNATURE_REQUESTED_VIA_STELLA"] = "MAKLERVERTRAG_SIGNATURE_REQUESTED_VIA_STELLA";
  TrackingTypeEnum["SIGNED_VOLLMACHT_STORED"] = "SIGNED_VOLLMACHT_STORED";
  TrackingTypeEnum["SIGNED_MAKLERVERTRAG_STORED"] = "SIGNED_MAKLERVERTRAG_STORED";
  TrackingTypeEnum["SVA_OFFERT_REQUESTED"] = "SVA_OFFERT_REQUESTED";
  TrackingTypeEnum["SVA_OFFERT_UPLOADED"] = "SVA_OFFERT_UPLOADED";
  TrackingTypeEnum["SVA_FINISHED"] = "SVA_FINISHED";
  TrackingTypeEnum["HVA_OFFERT_REQUESTED"] = "HVA_OFFERT_REQUESTED";
  TrackingTypeEnum["HVA_OFFERT_UPLOADED"] = "HVA_OFFERT_UPLOADED";
  TrackingTypeEnum["HVA_FINISHED"] = "HVA_FINISHED";
  TrackingTypeEnum["BVS_OFFERT_REQUESTED"] = "BVS_OFFERT_REQUESTED";
  TrackingTypeEnum["BVS_OFFERT_UPLOADED"] = "BVS_OFFERT_UPLOADED";
  TrackingTypeEnum["BVS_POLIZZE_REQUESTED"] = "BVS_POLIZZE_REQUESTED";
  TrackingTypeEnum["BVS_POLIZZE_UPLOADED"] = "BVS_POLIZZE_UPLOADED";
  TrackingTypeEnum["BVS_FINISHED"] = "BVS_FINISHED";
  TrackingTypeEnum["SKIPPER_HAFTPFLICHT_OFFERT_REQUESTED"] = "SKIPPER_HAFTPFLICHT_OFFERT_REQUESTED";
  TrackingTypeEnum["SKIPPER_HAFTPFLICHT_OFFERT_UPLOADED"] = "SKIPPER_HAFTPFLICHT_OFFERT_UPLOADED";
  TrackingTypeEnum["SKIPPER_HAFTPFLICHT_POLIZZE_REQUESTED"] = "SKIPPER_HAFTPFLICHT_POLIZZE_REQUESTED";
  TrackingTypeEnum["SKIPPER_HAFTPFLICHT_POLIZZE_UPLOADED"] = "SKIPPER_HAFTPFLICHT_POLIZZE_UPLOADED";
  TrackingTypeEnum["SKIPPER_HAFTPFLICHT_FINISHED"] = "SKIPPER_HAFTPFLICHT_FINISHED";
  TrackingTypeEnum["SKIPPER_RECHTSSCHUTZ_OFFERT_REQUESTED"] = "SKIPPER_RECHTSSCHUTZ_OFFERT_REQUESTED";
  TrackingTypeEnum["SKIPPER_RECHTSSCHUTZ_OFFERT_UPLOADED"] = "SKIPPER_RECHTSSCHUTZ_OFFERT_UPLOADED";
  TrackingTypeEnum["SKIPPER_RECHTSSCHUTZ_POLIZZE_REQUESTED"] = "SKIPPER_RECHTSSCHUTZ_POLIZZE_REQUESTED";
  TrackingTypeEnum["SKIPPER_RECHTSSCHUTZ_POLIZZE_UPLOADED"] = "SKIPPER_RECHTSSCHUTZ_POLIZZE_UPLOADED";
  TrackingTypeEnum["SKIPPER_RECHTSSCHUTZ_FINISHED"] = "SKIPPER_RECHTSSCHUTZ_FINISHED";
  TrackingTypeEnum["CHARTER_OFFERT_REQUESTED"] = "CHARTER_OFFERT_REQUESTED";
  TrackingTypeEnum["CHARTER_OFFERT_UPLOADED"] = "CHARTER_OFFERT_UPLOADED";
  TrackingTypeEnum["CHARTER_POLIZZE_REQUESTED"] = "CHARTER_POLIZZE_REQUESTED";
  TrackingTypeEnum["CHARTER_POLIZZE_UPLOADED"] = "CHARTER_POLIZZE_UPLOADED";
  TrackingTypeEnum["CHARTER_FINISHED"] = "CHARTER_FINISHED";
  TrackingTypeEnum["OFFERT_REJECTED"] = "OFFERT_REJECTED";
  TrackingTypeEnum["OFFERT_REQUESTED"] = "OFFERT_REQUESTED";
  TrackingTypeEnum["OFFERT_ACCEPTED"] = "OFFERT_ACCEPTED";
  TrackingTypeEnum["OFFERT_SIGNATURE_REQUESTED"] = "OFFERT_SIGNATURE_REQUESTED";
  TrackingTypeEnum["OFFERT_SIGNED_BY_CUSTOMER"] = "OFFERT_SIGNED_BY_CUSTOMER";
  TrackingTypeEnum["CUSTOMER_ACTION_REQUESTED"] = "CUSTOMER_ACTION_REQUESTED";
  TrackingTypeEnum["CUSTOMER_ACTION_PROVIDED"] = "CUSTOMER_ACTION_PROVIDED";
  TrackingTypeEnum["CUSTOMER_SIGNATURE_REQUESTED"] = "CUSTOMER_SIGNATURE_REQUESTED";
  TrackingTypeEnum["CUSTOMER_SIGNATURE_PROVIDED"] = "CUSTOMER_SIGNATURE_PROVIDED";
  TrackingTypeEnum["CUSTOMER_CREATED_BY_BROKER"] = "CUSTOMER_CREATED_BY_BROKER";
  TrackingTypeEnum["SKIPPER_OFFERT_REQUESTED"] = "SKIPPER_OFFERT_REQUESTED";
  TrackingTypeEnum["SKIPPER_OFFERT_UPLOADED"] = "SKIPPER_OFFERT_UPLOADED";
  TrackingTypeEnum["SKIPPER_POLIZZE_REQUESTED"] = "SKIPPER_POLIZZE_REQUESTED";
  TrackingTypeEnum["SKIPPER_POLIZZE_UPLOADED"] = "SKIPPER_POLIZZE_UPLOADED";
  TrackingTypeEnum["SKIPPER_FINISHED"] = "SKIPPER_FINISHED";
  TrackingTypeEnum["SERVO_KAUFPREIS_KASKO_FINISHED"] = "SERVO_KAUFPREIS_KASKO_FINISHED";
  TrackingTypeEnum["SERVO_GARANTIEVERLAENGERUNG_FINISHED"] = "SERVO_GARANTIEVERLAENGERUNG_FINISHED";
  TrackingTypeEnum["SERVO_ELEKTRONIK_FINISHED"] = "SERVO_ELEKTRONIK_FINISHED";
  TrackingTypeEnum["SERVO_BIKE_E_BIKE_FINISHED"] = "SERVO_BIKE_E_BIKE_FINISHED";
  TrackingTypeEnum["SERVO_POLIZZE_REQUESTED"] = "SERVO_POLIZZE_REQUESTED";
  TrackingTypeEnum["STELLA_INVITATION_SENT"] = "STELLA_INVITATION_SENT";
  TrackingTypeEnum["STELLA_ACCOUNT_CREATED"] = "STELLA_ACCOUNT_CREATED";
  TrackingTypeEnum["STELLA_ADD_INSURANCE_CLICKED"] = "STELLA_ADD_INSURANCE_CLICKED";
  TrackingTypeEnum["STELLA_SERVO_ELEKTRONIK_LINK_CLICKED"] = "STELLA_SERVO_ELEKTRONIK_LINK_CLICKED";
  TrackingTypeEnum["STELLA_SERVO_KAUFPREIS_LINK_CLICKED"] = "STELLA_SERVO_KAUFPREIS_LINK_CLICKED";
  TrackingTypeEnum["STELLA_SERVO_GARANTIESCHUTZ_LINK_CLICKED"] = "STELLA_SERVO_GARANTIESCHUTZ_LINK_CLICKED";
  TrackingTypeEnum["STELLA_SERVO_PREMIUMSCHUTZ_LINK_CLICKED"] = "STELLA_SERVO_PREMIUMSCHUTZ_LINK_CLICKED";
  TrackingTypeEnum["STELLA_SERVO_BIKE_LINK_CLICKED"] = "STELLA_SERVO_BIKE_LINK_CLICKED";
  TrackingTypeEnum["STELLA_CALLIDUS_KASKO_HAFTPFLICHT_LINK_CLICKED"] = "STELLA_CALLIDUS_KASKO_HAFTPFLICHT_LINK_CLICKED";
  TrackingTypeEnum["STELLA_CALLIDUS_HAFTPFLICHT_LINK_CLICKED"] = "STELLA_CALLIDUS_HAFTPFLICHT_LINK_CLICKED";
  TrackingTypeEnum["STELLA_CALLIDUS_SKIPPER_RECHTSSCHUTZ_LINK_CLICKED"] = "STELLA_CALLIDUS_SKIPPER_RECHTSSCHUTZ_LINK_CLICKED";
  TrackingTypeEnum["STELLA_CALLIDUS_SKIPPER_HAFTPFLICHT_LINK_CLICKED"] = "STELLA_CALLIDUS_SKIPPER_HAFTPFLICHT_LINK_CLICKED";
  TrackingTypeEnum["STELLA_CALLIDUS_CHARTERKAUTION_LINK_CLICKED"] = "STELLA_CALLIDUS_CHARTERKAUTION_LINK_CLICKED";
  TrackingTypeEnum["STELLA_CALLIDUS_CHARTER_HAFTPFLICHT_LINK_CLICKED"] = "STELLA_CALLIDUS_CHARTER_HAFTPFLICHT_LINK_CLICKED";
  TrackingTypeEnum["STELLA_BROKER_CONTACT_EMAIL_CLICKED"] = "STELLA_BROKER_CONTACT_EMAIL_CLICKED";
  TrackingTypeEnum["STELLA_BROKER_CONTACT_PHONE_CLICKED"] = "STELLA_BROKER_CONTACT_PHONE_CLICKED";
  TrackingTypeEnum["STELLA_BROKER_CONTACT_CHAT_CLICKED"] = "STELLA_BROKER_CONTACT_CHAT_CLICKED";
})(TrackingTypeEnum || (TrackingTypeEnum = {}));
var TrackingTypeEnumValues;
(function (TrackingTypeEnumValues) {
  function getLabelKeys() {
    return [{
      key: TrackingTypeEnum.ONBOARDING_SUBMITTED,
      label: 'tracking.onboardingSubmitted'
    }, {
      key: TrackingTypeEnum.CONTRACT_REVIEWED,
      label: 'tracking.reviewed'
    }, {
      key: TrackingTypeEnum.EXC_OFFERT_REQUESTED,
      label: 'tracking.offerExcedentRequested'
    }, {
      key: TrackingTypeEnum.EXC_OFFERT_UPLOADED,
      label: 'tracking.offerExcedentUploaded'
    }, {
      key: TrackingTypeEnum.EXC_FINISHED,
      label: 'tracking.policyExcedentFinished'
    }, {
      key: TrackingTypeEnum.BAU_EXC_OFFERT_REQUESTED,
      label: 'tracking.bauExcOffertRequested'
    }, {
      key: TrackingTypeEnum.BAU_EXC_OFFERT_UPLOADED,
      label: 'tracking.bauExcOffertUploaded'
    }, {
      key: TrackingTypeEnum.BAU_EXC_FINISHED,
      label: 'tracking.bauExcFinished'
    }, {
      key: TrackingTypeEnum.HOTEL_OFFERT_REQUESTED,
      label: 'tracking.hotelOfferRequested'
    }, {
      key: TrackingTypeEnum.HOTEL_OFFERT_UPLOADED,
      label: 'tracking.hotelOfferUploaded'
    }, {
      key: TrackingTypeEnum.HOTEL_FINISHED,
      label: 'tracking.hotelOfferFinished'
    }, {
      key: TrackingTypeEnum.CONTACT_CONSENT_GIVEN,
      label: 'tracking.contactConsentGiven'
    }, {
      key: TrackingTypeEnum.GENERAL_INSURANCE_CONDITIONS_ACCEPTED,
      label: 'tracking.insuranceConditionsAccepted'
    }, {
      key: TrackingTypeEnum.HOTEL_FOLDER_ACCEPTED,
      label: 'tracking.hotelFolderAccepted'
    }, {
      key: TrackingTypeEnum.VOLLMACHT_SENT_TO_VARIAS,
      label: 'tracking.sentToVarias'
    }, {
      key: TrackingTypeEnum.MAKLERVERTRAG_SENT_TO_VARIAS,
      label: 'tracking.brokerAgreementSentToVarias'
    }, {
      key: TrackingTypeEnum.VOLLMACHT_SIGNATURE_REQUESTED_VIA_MAIL,
      label: 'tracking.vollmachtSignature.mail'
    }, {
      key: TrackingTypeEnum.MAKLERVERTRAG_SIGNATURE_REQUESTED_VIA_MAIL,
      label: 'tracking.brokerAgreementSignature.mail'
    }, {
      key: TrackingTypeEnum.VOLLMACHT_SIGNATURE_REQUESTED_VIA_STELLA,
      label: 'tracking.vollmachtSignatureMail.vera'
    }, {
      key: TrackingTypeEnum.MAKLERVERTRAG_SIGNATURE_REQUESTED_VIA_STELLA,
      label: 'tracking.brokerAgreementSignature.vera'
    }, {
      key: TrackingTypeEnum.SIGNED_VOLLMACHT_STORED,
      label: 'tracking.signedVollmachtStored'
    }, {
      key: TrackingTypeEnum.SIGNED_MAKLERVERTRAG_STORED,
      label: 'tracking.signedBrokerAgreementStored'
    }, {
      key: TrackingTypeEnum.SVA_OFFERT_REQUESTED,
      label: 'tracking.svaOfferRequested'
    }, {
      key: TrackingTypeEnum.SVA_OFFERT_UPLOADED,
      label: 'tracking.svaOfferUploaded'
    }, {
      key: TrackingTypeEnum.SVA_FINISHED,
      label: 'tracking.svaPolicyFinished'
    }, {
      key: TrackingTypeEnum.HVA_OFFERT_REQUESTED,
      label: 'tracking.hvaOfferRequested'
    }, {
      key: TrackingTypeEnum.HVA_OFFERT_UPLOADED,
      label: 'tracking.hvaOfferUploaded'
    }, {
      key: TrackingTypeEnum.HVA_FINISHED,
      label: 'tracking.hvaPolicyFinished'
    }, {
      key: TrackingTypeEnum.BVS_OFFERT_REQUESTED,
      label: 'tracking.bvsOfferRequested'
    }, {
      key: TrackingTypeEnum.BVS_OFFERT_UPLOADED,
      label: 'tracking.bvsOfferUploaded'
    }, {
      key: TrackingTypeEnum.BVS_POLIZZE_REQUESTED,
      label: 'tracking.bvsPolicyRequested'
    }, {
      key: TrackingTypeEnum.BVS_POLIZZE_UPLOADED,
      label: 'tracking.bvsPolicyUploaded'
    }, {
      key: TrackingTypeEnum.BVS_FINISHED,
      label: 'tracking.bvsFinished'
    }, {
      key: TrackingTypeEnum.SKIPPER_HAFTPFLICHT_OFFERT_REQUESTED,
      label: 'tracking.skipperHaftpflichtOffertRequested'
    }, {
      key: TrackingTypeEnum.SKIPPER_HAFTPFLICHT_OFFERT_UPLOADED,
      label: 'tracking.skipperHaftpflichtOffertUploaded'
    }, {
      key: TrackingTypeEnum.SKIPPER_HAFTPFLICHT_POLIZZE_REQUESTED,
      label: 'tracking.skipperHaftpflichtPolicyRequested'
    }, {
      key: TrackingTypeEnum.SKIPPER_HAFTPFLICHT_POLIZZE_UPLOADED,
      label: 'tracking.skipperHaftpflichtPolicyUploaded'
    }, {
      key: TrackingTypeEnum.SKIPPER_HAFTPFLICHT_FINISHED,
      label: 'tracking.skipperHaftpflichtFinished'
    }, {
      key: TrackingTypeEnum.SKIPPER_RECHTSSCHUTZ_OFFERT_REQUESTED,
      label: 'tracking.skipperRechtsschutzOffertRequested'
    }, {
      key: TrackingTypeEnum.SKIPPER_RECHTSSCHUTZ_OFFERT_UPLOADED,
      label: 'tracking.skipperRechtsschutzOffertUploaded'
    }, {
      key: TrackingTypeEnum.SKIPPER_RECHTSSCHUTZ_POLIZZE_REQUESTED,
      label: 'tracking.skipperRechtsschutzPolicyRequested'
    }, {
      key: TrackingTypeEnum.SKIPPER_RECHTSSCHUTZ_POLIZZE_UPLOADED,
      label: 'tracking.skipperRechtsschutzPolicyUploaded'
    }, {
      key: TrackingTypeEnum.SKIPPER_RECHTSSCHUTZ_FINISHED,
      label: 'tracking.skipperRechtsschutzFinished'
    }, {
      key: TrackingTypeEnum.CHARTER_OFFERT_REQUESTED,
      label: 'tracking.charterOffertRequested'
    }, {
      key: TrackingTypeEnum.CHARTER_OFFERT_UPLOADED,
      label: 'tracking.charterOffertUploaded'
    }, {
      key: TrackingTypeEnum.CHARTER_POLIZZE_REQUESTED,
      label: 'tracking.charterPolicyRequested'
    }, {
      key: TrackingTypeEnum.CHARTER_POLIZZE_UPLOADED,
      label: 'tracking.charterPolicyUploaded'
    }, {
      key: TrackingTypeEnum.CHARTER_FINISHED,
      label: 'tracking.charterFinished'
    }, {
      key: TrackingTypeEnum.OFFERT_REJECTED,
      label: 'tracking.offertRejected'
    }, {
      key: TrackingTypeEnum.OFFERT_REQUESTED,
      label: 'tracking.offertRequested'
    }, {
      key: TrackingTypeEnum.OFFERT_ACCEPTED,
      label: 'tracking.offertAccepted'
    }, {
      key: TrackingTypeEnum.OFFERT_SIGNATURE_REQUESTED,
      label: 'tracking.offertSignatureRequested'
    }, {
      key: TrackingTypeEnum.OFFERT_SIGNED_BY_CUSTOMER,
      label: 'tracking.offertSignedByCustomer'
    }, {
      key: TrackingTypeEnum.CUSTOMER_ACTION_REQUESTED,
      label: 'tracking.customerActionRequested'
    }, {
      key: TrackingTypeEnum.CUSTOMER_ACTION_PROVIDED,
      label: 'tracking.customerActionProvided'
    }, {
      key: TrackingTypeEnum.CUSTOMER_SIGNATURE_REQUESTED,
      label: 'tracking.customerSignatureRequested'
    }, {
      key: TrackingTypeEnum.CUSTOMER_SIGNATURE_PROVIDED,
      label: 'tracking.customerSignatureProvided'
    }, {
      key: TrackingTypeEnum.CUSTOMER_CREATED_BY_BROKER,
      label: 'tracking.customerCreatedByBroker'
    }, {
      key: TrackingTypeEnum.SKIPPER_OFFERT_REQUESTED,
      label: 'tracking.skipperOffertRequested'
    }, {
      key: TrackingTypeEnum.SKIPPER_OFFERT_UPLOADED,
      label: 'tracking.skipperOffertUploaded'
    }, {
      key: TrackingTypeEnum.SKIPPER_POLIZZE_REQUESTED,
      label: 'tracking.skipperPolicyRequested'
    }, {
      key: TrackingTypeEnum.SKIPPER_POLIZZE_UPLOADED,
      label: 'tracking.skipperPolicyUploaded'
    }, {
      key: TrackingTypeEnum.SKIPPER_FINISHED,
      label: 'tracking.skipperFinished'
    }, {
      key: TrackingTypeEnum.SERVO_KAUFPREIS_KASKO_FINISHED,
      label: 'servo.kaufpreisKaskoFinished'
    }, {
      key: TrackingTypeEnum.SERVO_GARANTIEVERLAENGERUNG_FINISHED,
      label: 'servo.garantieVerlaengerungFinished'
    }, {
      key: TrackingTypeEnum.SERVO_ELEKTRONIK_FINISHED,
      label: 'servo.elektronikFinished'
    }, {
      key: TrackingTypeEnum.SERVO_BIKE_E_BIKE_FINISHED,
      label: 'servo.bikeEBikeFinished'
    }, {
      key: TrackingTypeEnum.SERVO_POLIZZE_REQUESTED,
      label: 'servo.policyRequested'
    }, {
      key: TrackingTypeEnum.STELLA_INVITATION_SENT,
      label: 'stella.invitationSent'
    }, {
      key: TrackingTypeEnum.STELLA_ACCOUNT_CREATED,
      label: 'stella.accountCreated'
    }, {
      key: TrackingTypeEnum.STELLA_ADD_INSURANCE_CLICKED,
      label: 'stella.addInsuranceClicked'
    }, {
      key: TrackingTypeEnum.STELLA_SERVO_ELEKTRONIK_LINK_CLICKED,
      label: 'stella.servoElektronikLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_SERVO_KAUFPREIS_LINK_CLICKED,
      label: 'stella.servoKaufpreisLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_SERVO_GARANTIESCHUTZ_LINK_CLICKED,
      label: 'stella.servoGarantieSchutzLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_SERVO_PREMIUMSCHUTZ_LINK_CLICKED,
      label: 'stella.servoPremiumSchutzLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_SERVO_BIKE_LINK_CLICKED,
      label: 'stella.servoBikeLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_CALLIDUS_KASKO_HAFTPFLICHT_LINK_CLICKED,
      label: 'stella.callidusKaskoHaftpflichtLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_CALLIDUS_HAFTPFLICHT_LINK_CLICKED,
      label: 'stella.callidusHaftpflichtLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_CALLIDUS_SKIPPER_RECHTSSCHUTZ_LINK_CLICKED,
      label: 'stella.callidusSkipperRechtsschutzLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_CALLIDUS_SKIPPER_HAFTPFLICHT_LINK_CLICKED,
      label: 'stella.callidusSkipperHaftpflichtLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_CALLIDUS_CHARTERKAUTION_LINK_CLICKED,
      label: 'stella.callidusCharterkautionLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_CALLIDUS_CHARTER_HAFTPFLICHT_LINK_CLICKED,
      label: 'stella.callidusCharterHaftpflichtLinkClicked'
    }, {
      key: TrackingTypeEnum.STELLA_BROKER_CONTACT_EMAIL_CLICKED,
      label: 'stella.brokerContactEmailClicked'
    }, {
      key: TrackingTypeEnum.STELLA_BROKER_CONTACT_PHONE_CLICKED,
      label: 'stella.brokerContactPhoneClicked'
    }, {
      key: TrackingTypeEnum.STELLA_BROKER_CONTACT_CHAT_CLICKED,
      label: 'stella.brokerContactChatClicked'
    }];
  }
  TrackingTypeEnumValues.getLabelKeys = getLabelKeys;
  function getEnumByKey(key) {
    return getLabelKeys().find(k => k.key === key);
  }
  TrackingTypeEnumValues.getEnumByKey = getEnumByKey;
})(TrackingTypeEnumValues || (TrackingTypeEnumValues = {}));
var TimespanEnum;
(function (TimespanEnum) {
  TimespanEnum["MONTH"] = "MONTH";
  TimespanEnum["QUARTER"] = "QUARTER";
  TimespanEnum["YEAR"] = "YEAR";
})(TimespanEnum || (TimespanEnum = {}));
const getErrorMessage = maxRetry => `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;
const DEFAULT_MAX_RETRIES = 5;
/**
 * performs a request again after the given delayInMS until the given amount of max retries is reached
 */
function delayedRetry(delayMs, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;
  return src => src.pipe(retryWhen(errors => errors.pipe(delay(delayMs), mergeMap(error => retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry))))));
}
var DevicePlatformEnum;
(function (DevicePlatformEnum) {
  DevicePlatformEnum["IOS"] = "IOS";
  DevicePlatformEnum["ANDROID"] = "ANDROID";
  DevicePlatformEnum["WEB"] = "WEB";
})(DevicePlatformEnum || (DevicePlatformEnum = {}));
function getPlatformEnum(value) {
  return DevicePlatformEnum[value.toUpperCase()];
}
var DeviceOperatingSystemEnum;
(function (DeviceOperatingSystemEnum) {
  DeviceOperatingSystemEnum["IOS"] = "IOS";
  DeviceOperatingSystemEnum["ANDROID"] = "ANDROID";
  DeviceOperatingSystemEnum["WINDOWS"] = "WINDOWS";
  DeviceOperatingSystemEnum["MAC"] = "MAC";
  DeviceOperatingSystemEnum["UNKNOWN"] = "UNKNOWN";
})(DeviceOperatingSystemEnum || (DeviceOperatingSystemEnum = {}));
function getOperatingSystemEnum(value) {
  return DeviceOperatingSystemEnum[value.toUpperCase()];
}
var TaskTypeEnum;
(function (TaskTypeEnum) {
  TaskTypeEnum["SIGNATURE_REQUESTED"] = "SIGNATURE_REQUESTED";
  TaskTypeEnum["ADDITIONAL_CUSTOMER_INFO_REQUESTED"] = "ADDITIONAL_CUSTOMER_INFO_REQUESTED";
})(TaskTypeEnum || (TaskTypeEnum = {}));
var NotificationTypeEnum;
(function (NotificationTypeEnum) {
  NotificationTypeEnum["SIGNATURE_REQUESTED"] = "SIGNATURE_REQUESTED";
  NotificationTypeEnum["NEW_CHAT_MESSAGE"] = "NEW_CHAT_MESSAGE";
  NotificationTypeEnum["NEW_DOCUMENT_AVAILABLE"] = "NEW_DOCUMENT_AVAILABLE";
  NotificationTypeEnum["ADDITIONAL_CUSTOMER_INFO_REQUESTED"] = "ADDITIONAL_CUSTOMER_INFO_REQUESTED";
})(NotificationTypeEnum || (NotificationTypeEnum = {}));

/*
 * Public API Surface of brokernet-ui-lib
 */
/**
 * pipes
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AdditionalInsuranceEnum, AuswahlSachverstaendigerEnum, AuswahlSachverstaendigerEnumValues, BauHauptGewerbeSektorEnum, BauHauptGewerbeSektorEnumValues, BauHilfsGewerbeSektorEnum, BauHilfsGewerbeSektorEnumValues, BauNebenGewerbeSektorEnum, BauNebenGewerbeSektorEnumValues, BicValidator, BrokerDocumentVersionActionEnum, BrokernetDocumentTypeEnum, BrokernetDocumentTypeEnumValues, BrokernetLanguageTypeEnum, BrokernetLanguageTypeValues, BuildingConstructionTypeEnum, BuildingRoofTypeEnum, BuildingTypeEnum, BuildingTypeEnumValues, ClaimStatusEnum, ClaimStatusEnumValues, CompanyTypeEnum, CompanyTypeEnumValues, ConfirmDialogResultEnum, ContractCategoryEnum, ContractStatusEnum, ContractStatusEnumValues, ContractTypeEnum, ContractTypeEnumValues, CountryEnum, CountryEnumValues, CustomerPermissionEnum, CustomerTypeEnum, CustomerTypeEnumValues, DateValidator, DayMonthValidator, DeckungRegionEnum, DeckungRegionEnumValues, DeviceOperatingSystemEnum, DevicePlatformEnum, GebaeudeTypeEnumValues, GenderEnum, GenderEnumValues, IbanFormatPipe, IbanFormatPipeModule, IbanValidator, InsuranceCompanyNameEnum, InsuranceCompanyNameEnumValues, InvestitionStatusEnum, InvestitionTypeEnum, JahresgespraechContactTypeEnum, JahresgespraechStatusEnum, LastUpdateSourceEnum, LegalFormEnum, LegalFormEnumValues, MinMaxValidator, MonthYearValidator, NotificationTypeEnum, OnboardingStatusEnum, PaymentPeriodEnum, PaymentPeriodEnumValues, PaymentTypeEnum, RegularCommissionTypeEnum, SpecialCommissionTypeEnum, StateEnum, TaskTypeEnum, TimespanEnum, TrackingTypeEnum, TrackingTypeEnumValues, UserPermissionEnum, capitalizeFirstLetter, createBauHaftpflichtContractBase, createContract, delayedRetry, getOperatingSystemEnum, getPlatformEnum, isFormGroupInvalidExcept, isNotNullOrUndefined, isNotNullOrUndefinedOrEmpty, isNotNullOrUndefinedOrZero, isNullOrUndefinedOrEmpty, isStringEmptyOrNull, setValidatorsOnAllControlsInForm };
