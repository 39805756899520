import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BvsHaftpflichtUndKaskoFormModel } from '../../models/form/bvs-haftpflicht-und-kasko-form.model';
import { CalldiusStepItem, CallidusStepperComponent } from '@shared/components/callidus-stepper/callidus-stepper.component';
import { BoatDataHaftpflichtKaskoComponent } from '../../components/boat-data-haftpflicht-kasko/boat-data-haftpflicht-kasko.component';
import { createBvsHaftpflichtKaskoForm } from '../../factories/bvs-kasko-form.factory';
import {
  AdditionalCoverageHaftpflichtKaskoDataComponent
} from '../../components/additional-coverage-haftpflicht-kasko-data/additional-coverage-haftpflicht-kasko-data.component';
import { CustomerDataComponent } from '@shared/components/customer-data/customer-data.component';
import { DataSubmittedComponent } from '@shared/components/data-submitted/data-submitted.component';
import { BoatDataHaftpflichtComponent } from '../../../bvs-haftpflicht/components/boat-data-haftpflicht/boat-data-haftpflicht.component';
import {
  AdditionalCoverageHaftpflichtDataComponent
} from '../../../bvs-haftpflicht/components/additional-coverage-haftpflicht-data/additional-coverage-haftpflicht-data.component';
import { PricingDataComponent } from '../../components/pricing-data/pricing-data.component';
import { takeUntil } from 'rxjs';
import { BvsBaseComponent } from '@shared/directives/bvs-base.component';
import { SubmitHaftpflichtKaskoCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { BrokernetLanguageTypeEnum, Person } from '@brokernet/brokernet-ui-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bvs-haftpflicht-kasko-page',
  standalone: true,
  imports: [
    BoatDataHaftpflichtKaskoComponent,
    CustomerDataComponent,
    CallidusStepperComponent,
    DataSubmittedComponent,
    PricingDataComponent,
    BoatDataHaftpflichtComponent,
    AdditionalCoverageHaftpflichtKaskoDataComponent,
    AdditionalCoverageHaftpflichtDataComponent
  ],
  templateUrl: './bvs-haftpflicht-kasko-page.component.html',
  styleUrl: './bvs-haftpflicht-kasko-page.component.scss'
})
export class BvsHaftpflichtKaskoPageComponent extends BvsBaseComponent implements OnInit {

  router = inject(Router);

  curentNavigationStep = HaftpflichtKaskoNavigationEnum.Base;
  form!: FormGroup<BvsHaftpflichtUndKaskoFormModel>;

  stepItems: CalldiusStepItem[] = [
    { id: '1', label: 'bvs.calculator.menu.boat', iconUrl: '/assets/icons/boat.svg' },
    { id: '2', label: 'bvs.calculator.menu.finance', iconUrl: '/assets/icons/euro-symbol.svg' },
    { id: '3', label: 'bvs.calculator.menu.finish', iconUrl: '/assets/icons/health-shield.svg' }
  ];

  protected readonly FormGroup = FormGroup;
  protected readonly HaftpflichtKaskoNavigationEnum = HaftpflichtKaskoNavigationEnum;

  private stepOrder: HaftpflichtKaskoNavigationEnum[] = [
    HaftpflichtKaskoNavigationEnum.Base,
    HaftpflichtKaskoNavigationEnum.Pricing,
    HaftpflichtKaskoNavigationEnum.AdditionalCoverage,
    HaftpflichtKaskoNavigationEnum.Customer,
    HaftpflichtKaskoNavigationEnum.Done
  ];

  ngOnInit(): void {
    this.initializeForm();
    this.form?.controls.bvsBoatInformation.controls.boatType.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(boatType => {
      if (boatType !== null) {
        this.setValidatorsBasedOnBoatType(this.form, boatType);
      }
    });
  }

  getCurrentPosition(): number {
    return this.stepOrder.indexOf(this.curentNavigationStep);
  }

  moveToNextStep(): void {
    this.curentNavigationStep++;
  }

  moveToPreviousStep(): void {
    if (this.curentNavigationStep === HaftpflichtKaskoNavigationEnum.Base) {
      this.router.navigate(['/']);
    }

    this.curentNavigationStep--;
  }

  submitData(): void {
    const submitCommand: SubmitHaftpflichtKaskoCommandModel = {
      person: {
        ...this.form?.controls.customerData.controls.person.getRawValue(),
        titlePrefix: null,
        titleSuffix: null,
        language: localStorage.getItem('app-language') === BrokernetLanguageTypeEnum.EN ? BrokernetLanguageTypeEnum.EN : BrokernetLanguageTypeEnum.DE
      } as unknown as NonNullable<Person>,
      bvsFiguresHaftpflichtUndKasko: this.form.controls.bvsFigures.getRawValue(),
      bvsBoatInformationHaftpflichtUndKasko: this.form.controls.bvsBoatInformation.getRawValue(),
      customerComment: this.form.controls.customerData.controls.customerComment.value!,
      contractType: this.form.controls.contractType.value!,
      // @ts-ignore
      customerLegalInformation: null,
      // @ts-ignore
      contractLegalChecks: null,
    };

    if (this.urlParamProviderService.getQueryData()?.customerId) {
      submitCommand.customerId = this.urlParamProviderService.getQueryData()!.customerId!;
    }

    if (this.urlParamProviderService.getQueryData()?.goAdvertisementNumber) {
      submitCommand.goAdvertisementNumber = this.urlParamProviderService.getQueryData()!.goAdvertisementNumber!;
    }

    if (this.urlParamProviderService.getQueryData()?.token) {
      submitCommand.token = this.urlParamProviderService.getQueryData()!.token!;
    }

    this.submit(submitCommand);
    this.curentNavigationStep = HaftpflichtKaskoNavigationEnum.Done;
  }

  private initializeForm() {
    this.form = createBvsHaftpflichtKaskoForm();
  }
}

export enum HaftpflichtKaskoNavigationEnum {
  Base,
  Pricing,
  AdditionalCoverage,
  Customer,
  Done
}

