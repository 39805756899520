import { Component, inject, OnInit } from '@angular/core';
import { CalldiusStepItem, CallidusStepperComponent } from '@shared/components/callidus-stepper/callidus-stepper.component';
import { CustomerDataComponent } from '@shared/components/customer-data/customer-data.component';
import { DataSubmittedComponent } from '@shared/components/data-submitted/data-submitted.component';
import { UrlParamProviderService } from '@shared/services/url-param-provider.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { unsubscribeMixin } from '@shared/mixin/unsubscribe.mixin';
import { CharterKautionsversicherungComponent } from '../../components/charter-kautionsversicherung/charter-kautionsversicherung.component';
import { createBvsCharterKautionsversicherungForm } from '../../factories/bvs-charter-kautionsversicherung-form.factory';
import { BvsCharterKautionsversicherungFormModel } from '../../models/forms/bvs-charter-kautionsversicherung-form.model';
import { SubmitCharterKautionsversicherungCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { BrokernetLanguageTypeEnum, Person } from '@brokernet/brokernet-ui-lib';
import { CharterkautionApiService } from '../../services/charterkaution-api.service';

@Component({
  selector: 'app-charter-kautionsversicherung-page',
  standalone: true,
  imports: [
    CallidusStepperComponent,
    CustomerDataComponent,
    DataSubmittedComponent,
    CharterKautionsversicherungComponent
  ],
  providers: [CharterkautionApiService],
  templateUrl: './charter-kautionsversicherung-page.component.html',
  styleUrl: './charter-kautionsversicherung-page.component.scss'
})
export class CharterKautionsversicherungPageComponent extends unsubscribeMixin() implements OnInit {
  urlParamProviderService = inject(UrlParamProviderService);
  router = inject(Router);
  charterkautionApiService = inject(CharterkautionApiService);

  stepItems: CalldiusStepItem[] = [
    { id: '0', label: 'bvs.calculator.menu.infos', iconUrl: '/assets/icons/info-circle.svg' },
    { id: '1', label: 'bvs.calculator.menu.finish', iconUrl: '/assets/icons/health-shield.svg' }
  ];

  curentNavigationStep: BvsCharterKautionsversicherungNavigationEnum = BvsCharterKautionsversicherungNavigationEnum.Base;
  form!: FormGroup<BvsCharterKautionsversicherungFormModel>;

  protected readonly SkipperHaftpflichtNavigationEnum = BvsCharterKautionsversicherungNavigationEnum;

  private stepOrder: BvsCharterKautionsversicherungNavigationEnum[] = [
    BvsCharterKautionsversicherungNavigationEnum.Base,
    BvsCharterKautionsversicherungNavigationEnum.Customer,
    BvsCharterKautionsversicherungNavigationEnum.Done
  ];

  ngOnInit(): void {
    this.initializeForm();
  }

  getCurrentPosition(): number {
    return this.stepOrder.indexOf(this.curentNavigationStep);
  }

  moveToNextStep(): void {
    this.curentNavigationStep++;
  }

  moveToPreviousStep(): void {
    if (this.curentNavigationStep === BvsCharterKautionsversicherungNavigationEnum.Base) {
      this.router.navigate(['/']);
    }

    this.curentNavigationStep--;
  }

  submitData(): void {
    const submitCommand: SubmitCharterKautionsversicherungCommandModel = {
      person: {
        ...this.form?.controls.customerData.controls.person.getRawValue(),
        titlePrefix: null,
        titleSuffix: null,
        language: localStorage.getItem('app-language') === BrokernetLanguageTypeEnum.EN ? BrokernetLanguageTypeEnum.EN : BrokernetLanguageTypeEnum.DE
      } as unknown as NonNullable<Person>,
      ...this.form.getRawValue(),
      customerComment: this.form.controls.customerData.controls.customerComment.value!,
      contractType: this.form.controls.contractType.value!,
      //@ts-ignore
      customerLegalInformation: null,
    };

    if (this.urlParamProviderService.getQueryData()?.customerId) {
      submitCommand.customerId = this.urlParamProviderService.getQueryData()!.customerId!;
    }

    if (this.urlParamProviderService.getQueryData()?.goAdvertisementNumber) {
      submitCommand.goAdvertisementNumber = this.urlParamProviderService.getQueryData()!.goAdvertisementNumber!;
    }

    if (this.urlParamProviderService.getQueryData()?.token) {
      submitCommand.token = this.urlParamProviderService.getQueryData()!.token!;
    }

    this.charterkautionApiService.submit(submitCommand).subscribe();
    this.curentNavigationStep = BvsCharterKautionsversicherungNavigationEnum.Done;
  }

  private initializeForm(): void {
    this.form = createBvsCharterKautionsversicherungForm();
  }

}

export enum BvsCharterKautionsversicherungNavigationEnum {
  Base,
  Customer,
  Done
}
