import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenderEnum } from '@shared/models/gender.enum';
import { BrokernetLanguageTypeEnum, DateValidator } from '@brokernet/brokernet-ui-lib';
import { PersonFormModel } from '@shared/models/forms/person-form.model';

export function createPersonForm(): FormGroup<PersonFormModel> {
  return new FormGroup<PersonFormModel>({
    id: new FormControl<string | null>(null),
    titlePrefix: new FormControl<string | null>(null),
    firstname: new FormControl<string | null>(null, [Validators.required]),
    lastname: new FormControl<string | null>(null, [Validators.required]),
    titleSuffix: new FormControl<string | null>(null),
    gender: new FormControl<GenderEnum | null>(null, [Validators.required]),
    birthdate: new FormControl<string | null>(null, [DateValidator.maxDate(new Date()), Validators.required]),
    //TODO: find out how to set the language. Use a dropdown or use the website's language?
    language: new FormControl<BrokernetLanguageTypeEnum | null>(BrokernetLanguageTypeEnum.DE, [Validators.required]),
    email: new FormControl<string | null>(null, [Validators.email, Validators.required]),
    phone: new FormControl<string | null>(null, [Validators.required])
  });
}

