import { Routes } from '@angular/router';
import { BvsHaftpflichtPageComponent } from './features/bvs-haftpflicht/page/bvs-haftpflicht-page/bvs-haftpflicht-page.component';
import { BvsHaftpflichtKaskoPageComponent } from './features/bvs-haftpflicht-kasko/pages/bvs-haftpflicht-kasko-page/bvs-haftpflicht-kasko-page.component';
import {
  CharterKautionsversicherungPageComponent
} from './features/bvs-charter-kautionsversicherung/pages/charter-kautionsversicherung-page/charter-kautionsversicherung-page.component';
import { SkipperPageComponent } from './features/skipper/pages/skipper-page/skipper-page.component';
import { AppComponent } from './app.component';

export const routes: Routes = [
  {
    path: 'bvs/haftpflicht',
    loadComponent: () => BvsHaftpflichtPageComponent
  },
  {
    path: 'bvs/kasko',
    loadComponent: () => BvsHaftpflichtKaskoPageComponent
  },
  {
    path: 'bvs/charter',
    loadComponent: () => CharterKautionsversicherungPageComponent
  },
  {
    path: 'skipper',
    loadComponent: () => SkipperPageComponent
  },
  {
    path: 'stella',
    component: AppComponent
  }
];
