import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { DropdownModule } from 'primeng/dropdown';

import { AnswerOption, QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { GenderEnum } from '@shared/models/gender.enum';
import { InputNumberModule } from 'primeng/inputnumber';
import { LegalCheckBoxComponent } from '../legal-check-box/legal-check-box.component';
import { LegalChecksDataComponent } from '../legal-checks-data/legal-checks-data.component';
import { ContactDataComponent } from '../contact-data/contact-data.component';
import { DataBaseComponent } from '@shared/directives/data-base.component';
import { CustomerFormModel } from '@shared/models/forms/customer-form.model';
import { ButtonBusyDirective } from '@shared/directives/button-busy.directive';
import { UrlParamProviderService } from '@shared/services/url-param-provider.service';

@Component({
  selector: 'app-customer-data',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoPipe,
    DropdownModule,
    QuestionBoxComponent,
    InputNumberModule,
    LegalCheckBoxComponent,
    LegalChecksDataComponent,
    ContactDataComponent,
    ButtonBusyDirective
  ],
  templateUrl: './customer-data.component.html',
  styleUrl: './customer-data.component.scss'
})
export class CustomerDataComponent extends DataBaseComponent implements OnInit {

  urlParamProviderService = inject(UrlParamProviderService);

  @Input() form!: FormGroup<CustomerFormModel>;

  @Output() submitted = new EventEmitter<void>();

  maxBirthdate!: string;
  sendingRequest: boolean = false;
  genderAnswerOptions: AnswerOption<GenderEnum>[] = [
    { title: 'shared.maleGender', id: GenderEnum.MALE, value: GenderEnum.MALE },
    { title: 'shared.femaleGender', id: GenderEnum.FEMALE, value: GenderEnum.FEMALE },
    { title: 'shared.undefinedGender', id: GenderEnum.DIVERS, value: GenderEnum.DIVERS }
  ];

  ngOnInit(): void {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));

    /**
     * sets the date of today as last valid date for a birthdate, including the timezone
     * format: yyyy-mm-dd
     */
    this.maxBirthdate = date.toISOString().split('T')[0];
    this.patchUrlParamsToForm();
  }

  private patchUrlParamsToForm(): void {
    if (this.urlParamProviderService.getQueryData()) {
      const personCtrl = this.form.controls.person.controls;

      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.firstname, personCtrl.firstname);
      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.lastname, personCtrl.lastname);
      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.email, personCtrl.email);
      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.phone, personCtrl.phone);
      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.gender, personCtrl.gender);
      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.birthDate, personCtrl.birthdate);
      this.patchSingleParamToFormControl(this.urlParamProviderService.getQueryData()?.phone, personCtrl.phone);
    }
  }

  private patchSingleParamToFormControl(paramValue: any, ctrl: FormControl): void {
    if (!ctrl.value && paramValue) {
      ctrl.setValue(paramValue);
    }
  }

  sendCustomerData(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }
    this.sendingRequest = true;
    this.submitted.emit();
  }

  canShowContactData(): boolean {
    return this.form.controls.person.controls.firstname.value !== null
      && this.form.controls.person.controls.lastname.value !== null
      && this.form.controls.person.controls.birthdate.value !== null;
  }

  canShowLegalChecks(): boolean {
    return this.canShowContactData()
      && this.form.controls.person.controls.phone.value !== null
      && this.form.controls.person.controls.email.value !== null;
  }

  canShowAdditionalInformation(): boolean {
    const legalCtrls = this.form.controls.legalChecks.controls;
    return legalCtrls.isContactConsent.value === true
      && legalCtrls.isGdprAccepted.value === true;
  }

  canSubmit(): boolean {
    const legalForm = this.form.controls.legalChecks;

    return legalForm.controls.isContactConsent.value === true
      && legalForm.controls.isGdprAccepted.value === true;
  }

}

