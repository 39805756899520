import { Directive, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { unsubscribeMixin } from '@shared/mixin/unsubscribe.mixin';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';
import { BvsHaftpflichtFormModel } from '../../features/bvs-haftpflicht/models/form/bvs-haftpflicht-form.model';
import { BvsHaftpflichtUndKaskoFormModel } from '../../features/bvs-haftpflicht-kasko/models/form/bvs-haftpflicht-und-kasko-form.model';
import { BvsApiService } from '@shared/services/bvs-api.service';
import { SubmitHaftpflichtCommandModel, SubmitHaftpflichtKaskoCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { UrlParamProviderService } from '@shared/services/url-param-provider.service';

@Directive()
export class BvsBaseComponent extends unsubscribeMixin() {
  urlParamProviderService = inject(UrlParamProviderService);
  apiService = inject(BvsApiService);

  setValidatorsBasedOnBoatType(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>, boatType: BoatTypeEnum): void {
    if (boatType === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
      this.addSegelValidators(form);
      this.removeMotorValidators(form);
      this.removeMaxKnotValidators(form);
    } else if (boatType === BoatTypeEnum.MOTORBOOT_MOTORYACHT) {
      this.addMotorValidators(form);
      this.addMaxKnotValidators(form);
      this.removeSegelValidators(form);
    }
  }

  submit(bvs: SubmitHaftpflichtKaskoCommandModel | SubmitHaftpflichtCommandModel): void {
    this.apiService
      .submit(bvs)
      .subscribe({
        next: () => {
        },
        error: () => {
        }
      });
  }

  private addSegelValidators(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>): void {
    form.controls.bvsBoatInformation.controls.sailArea.addValidators(Validators.required);
    form.controls.bvsBoatInformation.controls.mastMaterial.addValidators(Validators.required);
  }

  private removeSegelValidators(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>): void {
    form.controls.bvsBoatInformation.controls.sailArea.removeValidators(Validators.required);
    form.controls.bvsBoatInformation.controls.mastMaterial.removeValidators(Validators.required);

    form.controls.bvsBoatInformation.controls.sailArea.updateValueAndValidity();
    form.controls.bvsBoatInformation.controls.mastMaterial.updateValueAndValidity();
  }

  private addMotorValidators(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>): void {
    form.controls.bvsBoatInformation.controls.engineHp.addValidators(Validators.required);
  }

  private removeMotorValidators(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>): void {
    form.controls.bvsBoatInformation.controls.engineHp.removeValidators(Validators.required);
    form.controls.bvsBoatInformation.controls.boatConstructionType.removeValidators(Validators.required);

    form.controls.bvsBoatInformation.controls.engineHp.updateValueAndValidity();
    form.controls.bvsBoatInformation.controls.boatConstructionType.updateValueAndValidity();
  }

  private addMaxKnotValidators(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>): void {
    form.controls.bvsBoatInformation.controls.maxKnotsOver40.addValidators([Validators.required, Validators.max(40)]);
  }

  private removeMaxKnotValidators(form: FormGroup<BvsHaftpflichtFormModel> | FormGroup<BvsHaftpflichtUndKaskoFormModel>): void {
    form.controls.bvsBoatInformation.controls.maxKnotsOver40.removeValidators(Validators.required);

    form.controls.bvsBoatInformation.controls.maxKnotsOver40.updateValueAndValidity();
  }
}
