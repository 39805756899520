import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CalldiusStepItem, CallidusStepperComponent } from '@shared/components/callidus-stepper/callidus-stepper.component';
import { BrokernetLanguageTypeEnum, Person } from '@brokernet/brokernet-ui-lib';
import { BoatDataHaftpflichtComponent } from '../../components/boat-data-haftpflicht/boat-data-haftpflicht.component';
import { CustomerDataComponent } from '@shared/components/customer-data/customer-data.component';
import {
  AdditionalCoverageHaftpflichtDataComponent
} from '../../components/additional-coverage-haftpflicht-data/additional-coverage-haftpflicht-data.component';
import { takeUntil } from 'rxjs';
import { DataSubmittedComponent } from '@shared/components/data-submitted/data-submitted.component';
import { SubmitHaftpflichtCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { BvsBaseComponent } from '@shared/directives/bvs-base.component';
import { BvsHaftpflichtFormModel } from '../../models/form/bvs-haftpflicht-form.model';
import { createBvsHaftpflichtForm } from '../../factories/bvs-haftpflicht-form.factory';
import {
  AdditionalCoverageHaftpflichtKaskoDataComponent
} from '../../../bvs-haftpflicht-kasko/components/additional-coverage-haftpflicht-kasko-data/additional-coverage-haftpflicht-kasko-data.component';
import { BoatDataHaftpflichtKaskoComponent } from '../../../bvs-haftpflicht-kasko/components/boat-data-haftpflicht-kasko/boat-data-haftpflicht-kasko.component';
import { PricingDataComponent } from '../../../bvs-haftpflicht-kasko/components/pricing-data/pricing-data.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bvs-haftpflicht-page',
  standalone: true,
  imports: [
    CallidusStepperComponent,
    BoatDataHaftpflichtComponent,
    CustomerDataComponent,
    AdditionalCoverageHaftpflichtDataComponent,
    DataSubmittedComponent,
    AdditionalCoverageHaftpflichtKaskoDataComponent,
    BoatDataHaftpflichtKaskoComponent,
    PricingDataComponent
  ],
  templateUrl: './bvs-haftpflicht-page.component.html',
  styleUrl: './bvs-haftpflicht-page.component.scss'
})
export class BvsHaftpflichtPageComponent extends BvsBaseComponent implements OnInit {
  router = inject(Router);

  form!: FormGroup<BvsHaftpflichtFormModel>;
  curentNavigationStep = HaftpflichtNavigationEnum.Base;

  stepItems: CalldiusStepItem[] = [
    { id: '0', label: 'bvs.calculator.menu.boat', iconUrl: '/assets/icons/boat.svg' },
    { id: '1', label: 'bvs.calculator.menu.finish', iconUrl: '/assets/icons/health-shield.svg' }
  ];
  protected readonly HaftpflichtNavigationEnum = HaftpflichtNavigationEnum;
  protected readonly FormGroup = FormGroup;

  private stepOrder: HaftpflichtNavigationEnum[] = [
    HaftpflichtNavigationEnum.Base,
    HaftpflichtNavigationEnum.AdditionalCoverage,
    HaftpflichtNavigationEnum.Customer,
    HaftpflichtNavigationEnum.Done
  ];

  getCurrentPosition(): number {
    return this.stepOrder.indexOf(this.curentNavigationStep);
  }

  moveToNextStep(): void {
    this.curentNavigationStep++;
  }

  moveToPreviousStep(): void {
    if (this.curentNavigationStep === HaftpflichtNavigationEnum.Base) {
      this.router.navigate(['/']);
    }
    this.curentNavigationStep--;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.form?.controls.bvsBoatInformation.controls.boatType.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(boatType => {
      if (boatType !== null) {
        this.setValidatorsBasedOnBoatType(this.form, boatType);
      }
    });
  }

  submitData(): void {
    const submitCommand: SubmitHaftpflichtCommandModel = {
      person: {
        ...this.form?.controls.customerData.controls.person.getRawValue(),
        titlePrefix: null,
        titleSuffix: null,
        language: localStorage.getItem('app-language') === BrokernetLanguageTypeEnum.EN ? BrokernetLanguageTypeEnum.EN : BrokernetLanguageTypeEnum.DE
      } as unknown as NonNullable<Person>,
      bvsFiguresHaftpflicht: this.form.controls.figures.getRawValue(),
      bvsBoatInformationHaftpflicht: this.form.controls.bvsBoatInformation.getRawValue(),
      customerComment: this.form.controls.customerData.controls.customerComment.value!,
      contractType: this.form.controls.contractType.value!,
      // @ts-ignore
      customerLegalInformation: null,
      // @ts-ignore
      contractLegalChecks: null,
    };

    if (this.urlParamProviderService.getQueryData()?.customerId) {
      submitCommand.customerId = this.urlParamProviderService.getQueryData()!.customerId!;
    }

    if (this.urlParamProviderService.getQueryData()?.goAdvertisementNumber) {
      submitCommand.goAdvertisementNumber = this.urlParamProviderService.getQueryData()!.goAdvertisementNumber!;
    }

    if (this.urlParamProviderService.getQueryData()?.token) {
      submitCommand.token = this.urlParamProviderService.getQueryData()!.token!;
    }

    this.submit(submitCommand);
    this.curentNavigationStep = HaftpflichtNavigationEnum.Done;
  }

  private initializeForm() {
    this.form = createBvsHaftpflichtForm();
  }
}

export enum HaftpflichtNavigationEnum {
  Base,
  AdditionalCoverage,
  Customer,
  Done
}
